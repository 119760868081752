//import "@fontsource/montserrat";
import React from "react";
import "./index.css";
import Profile from "./Components/viewUser";
import Auth from "./Components/Authuser";
//import "./i18n";
import UserNav from "./Components/UserNav";
import LoginContextProvider from "./Components/Context/LoginContext";

const UserProfilePage = () => {
  return (
    <>
    <LoginContextProvider>
      <React.StrictMode>
        <Auth/>
      <UserNav />
      <Profile/>
      </React.StrictMode>
      </LoginContextProvider>
    </>
  );
};

export default UserProfilePage;
