//import "@fontsource/montserrat";
import React from "react";
import "./index.css";
import Login from "./Components/login";
//import "./i18n";
import Button from 'react-bootstrap/Button';
import LoginContextProvider from "./Components/Context/LoginContext";
import MedicalContextProvider from "./Components/Context/MedicalContext";
//import ContextProvider from "./Components/Context/Context"; aquí importarían su context provider

const LoginPage = () => {
  return (
    <>
    <MedicalContextProvider>
    <LoginContextProvider>
       <React.StrictMode>  
      <Login/>
      </React.StrictMode>
      </LoginContextProvider>
      </MedicalContextProvider>
    </>
  );
};

export default LoginPage;
