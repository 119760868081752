//import "@fontsource/montserrat";
import React from "react";
import "./index.css";
import UserNav from "./Components/UserNav";
//import Footer from "./Componentes/Fotter";
import Forms from "./Components/forms";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MedicalContextProvider from "./Components/Context/MedicalContext";
import AuthUser from "./Components/Authuser";
import LogContextProvider from "./Components/Context/LoginContext";
import FormsContextProvider from "./Components/Context/formsContext";

function FormsPage() {
  return (
    <>
      <MedicalContextProvider>
        <FormsContextProvider>
        <LogContextProvider>
          <AuthUser/>
          <UserNav />
          <Forms />
          </LogContextProvider>
        </FormsContextProvider>
      </MedicalContextProvider>
    </>
  );
};

export default FormsPage;
