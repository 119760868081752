import React from "react";
import { useState, createContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';


export const MedicalContext = createContext();

const MedicalContextProvider = (props) => {
  const { t } = useTranslation()
  const [OutputListOfExams, SetOutputListOfExams] = useState(() => {
    const localData = localStorage.getItem("OutputListOfExams");
    return localData ? JSON.parse(localData) : [];
  });

  const [age, setage] = useState(() => {
    const localData = localStorage.getItem("age");
    return localData ? JSON.parse(localData) : [];
  });

  const [ListOfPersonalMedicalHistory, SetListOfPersonalMedicalHistory] =
    useState(() => {
      const localData = localStorage.getItem("ListOfPersonalMedicalHistory");
      return localData ? JSON.parse(localData) : [];
    });
  const [ListOfFamilyMedicalHistory, SetListOfFamilyMedicalHistory] = useState(
    () => {
      const localData = localStorage.getItem("ListOfFamilyMedicalHistory");
      return localData ? JSON.parse(localData) : [];
    }
  );
  const [Sobrepeso, setSobrepeso] = useState(() => {
    const localData = localStorage.getItem("Sobrepeso");
    return localData ? JSON.parse(localData) : false;
  });
  const [ObesidadI, setObesidadI] = useState(() => {
    const localData = localStorage.getItem("ObesidadI");
    return localData ? JSON.parse(localData) : false;
  });
  const [ObesidadII, setObesidadII] = useState(() => {
    const localData = localStorage.getItem("ObesidadII");
    return localData ? JSON.parse(localData) : false;
  });
  const [Hipotiroidismos, setHipotiroidismos] = useState(() => {
    const localData = localStorage.getItem("Hipotiroidismos");
    return localData ? JSON.parse(localData) : false;
  });
  const [DisplidemiaLeve, setDisplidemiaLeve] = useState(() => {
    const localData = localStorage.getItem("DisplidemiaLeve");
    return localData ? JSON.parse(localData) : false;
  });
  const [DisplidemiaSevera, setDisplidemiaSevera] = useState(
    () => {
      const localData = localStorage.getItem("DisplidemiaSevera");
      return localData ? JSON.parse(localData) : false;
    }
  );
  const [GlicemiaAyunas, setGlicemiaAyunas] = useState(() => {
    const localData = localStorage.getItem("GlicemiaAyunas");
    return localData ? JSON.parse(localData) : false;
  });
  const [Hiperuricemia, setHiperuricemia] = useState(() => {
    const localData = localStorage.getItem("Hiperuricemia");
    return localData ? JSON.parse(localData) : false;
  });
  const [TriglicéridosColesterol, setTriglicéridosColesterol] = useState(() => {
    const localData = localStorage.getItem("TriglicéridosColesterol");
    return localData ? JSON.parse(localData) : false;
  });
  const [Diabético, setDiabético] = useState(() => {
    const localData = localStorage.getItem("Diabético");
    return localData ? JSON.parse(localData) : false;
  });
  const [Tabaquismo5, setTabaquismo5] = useState(() => {
    const localData = localStorage.getItem("Tabaquismo5");
    return localData ? JSON.parse(localData) : false;
  });
  const [Tabaquismo10, setTabaquismo10] = useState(() => {
    const localData = localStorage.getItem("Tabaquismo10");
    return localData ? JSON.parse(localData) : false;
  });
  const [DiabéticoGlicemia, setDiabéticoGlicemia] = useState(() => {
    const localData = localStorage.getItem("DiabéticoGlicemia");
    return localData ? JSON.parse(localData) : false;
  });
  const [SedentaryLifeStyle, setSedentaryLifeStyle] = useState(() => {
    const localData = localStorage.getItem("SedentaryLifeStyle");
    return localData ? JSON.parse(localData) : false;
  });
  const [Obesity, setObesity] = useState(() => {
    const localData = localStorage.getItem("Obesity");
    return localData ? JSON.parse(localData) : false;
  });
  const [AllergicRhinitis, setAllergicRhinitis] = useState(() => {
    const localData = localStorage.getItem("AllergicRhinitis");
    return localData ? JSON.parse(localData) : false;
  });
  const [InsulinResistancePersonal, setInsulinResistancePersonal] = useState(
    () => {
      const localData = localStorage.getItem("InsulinResistancePersonal");
      return localData ? JSON.parse(localData) : false;
    }
  );
  const [ListOfAvailibleExams, SetListOfAvailibleExams] = useState(() => {
    const localData = localStorage.getItem("ListOfAvailibleExams");
    return localData ? JSON.parse(localData) : [];
  });

  const [ListOfAvailiblePacks, SetListOfAvailiblePacks] = useState(() => {
    const localData = localStorage.getItem("ListOfAvailiblePacks");
    return localData ? JSON.parse(localData) : [];
  });


  const CreateExamRequirements = (
    name,
    emission,
    emissiontext,
    preparation,
    preparationtext,
    /*tip,*/ ages,
    records,
    genderReq,
    subtitulo,
    code,
    id
  ) => {
    const Exam = {
      Name: name,
      Emission: emission,
      Emissiontext: emissiontext,
      Preparation: preparation,
      Preparationtext: preparationtext,
      /* Tip: tip,*/
      Ages: ages,
      Records: records,
      GenderReq: genderReq,
      Subtitulo:subtitulo,
      Code: code,
      ID: id,
    };

    return Exam;
  };

  

  const Hemograma = CreateExamRequirements(
    'Hemograma y VHS',
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [15],
    [],
    [],
    "Recomendaciones por rango etario",
    301045,
    0
  );
  const CProtein = CreateExamRequirements(
    'Proteína C Reactiva',
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [15],
    [],
    [],    
    "Recomendaciones por rango etario",
    305031,
    1
  );
  const PerfilLipidico = CreateExamRequirements(
    'Perfil Lipídico',
    1,
    "Laboratorio",
    2,
    'Ayuno 8-12hrs',
    [15],
    [],
    [],
    "Recomendaciones por rango etario",
    302034,
    2
  );
  const PerfilHepatico = CreateExamRequirements(
    'Perfil Hepático',
    1,
    "Laboratorio",
    2,
    'Ayuno 8-12hrs',
    [15],
    [],
    [],
    "Recomendaciones por rango etario",
    302076,
    3
  );
  const PerfilBioquimico = CreateExamRequirements(
    'Perfil Bioquímico',
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [15],
    [],
    [],
    "Recomendaciones por rango etario",
    302075,
    4
  );
  const Creatinina = CreateExamRequirements(
    'Creatinina',
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [15],
    [],
    [],
    "Recomendaciones por rango etario",
    302023,
    5
  );
  const ElectrolitosPlTriglicéridosColesterolticos = CreateExamRequirements(
    'Electrolitos Plasmáticos',
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [15],
    [],
    [],
    "Recomendaciones por rango etario",
    302032,
    6
  );
  const T4Libre = CreateExamRequirements(
    'T4 Libre',
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [15],
    [],
    [],
    "Recomendaciones por rango etario",
    303026,
    7
  );
  const TSH = CreateExamRequirements(
    'TSH',
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [15],
    [],
    [],
    "Recomendaciones por rango etario",
    303024,
    8
  );
  const PAP = CreateExamRequirements(
    'Papanicolau (PAP)',
    1,
    "Laboratorio",
    3,
    'Consultar indicaciones especiales',
    [15],
    [],
    [2],
    "Recomendaciones por rango etario",
    801001,
    9
  );
  const APE = CreateExamRequirements(
    'APE (Antígeno prostático específico) Libre y Total',
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [40],
    [],
    [1],
    "Recomendaciones por rango etario",
    305070,
    10
  );
  const HemorragiaOculta = CreateExamRequirements(
    'Test de hemorragia oculta en deposiciones',
    1,
    "Laboratorio",
    3,
    'Consultar indicaciones especiales',
    [],
    [17],
    [],
    ["Recomendaciones por rango etario","En Basese tu antecedentes Familiares"],
    308004,
    11
  );
  const Ecografia = CreateExamRequirements(
    'Ecografía mamaria bilateral',
    2,
    "Imagenología",
    3,
    'Consultar indicaciones especiales',
    [],
    [4],
    [2],
    ["Recomendaciones por rango etario","En Basese tu antecedentes Familiares"],
    404012,
    12
  );
  const Mamografia = CreateExamRequirements(
    'Mamografía bilateral',
    2,
    "Imagenología",
    3,
    'Consultar indicaciones especiales',
    [40],
    [],
    [2],
    "Recomendaciones por rango etario",
    401071,
    13
  );
  const Densitometria = CreateExamRequirements(
    'Densitometría ósea',
    2,
    "Imagenología",
    3,
    'Consultar indicaciones especiales',
    [65],
    [],
    [2],
    "Recomendaciones por rango etario",
    501134,
    14
  );
  const ElectroCardiograma = CreateExamRequirements(
    'Electrocardiograma',
    3,
    "Procedimientos",
    1,
    'No necesita preparación',
    [],
    [],
    [1],
    ["Recomendaciones por rango etario","En Basese tu antecedentes Familiares","En Basese tu antecedentes Personales"],
    1701001,
    15
  );
  const VitaminaD = CreateExamRequirements(
    '25-OH Vitamina D',
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [9],
    [],
    "En Basese tu antecedentes Personales",
    302078,
    16
  );
  const VitaminaB12 = CreateExamRequirements(
    'Vitamina B12',
    1,
    "Laboratorio",
    2,
    'Ayuno 8-12hrs',
    [],
    [9],
    [],
    "En Basese tu antecedentes Personales",
    302077,
    17
  );
  const Testosterona = CreateExamRequirements(
    'Testosterona total',
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [7],
    [],
    "En Basese tu antecedentes Personales",
    303022,
    18
  );
  const SHBG = CreateExamRequirements(
    "SHBG",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [7],
    [],
    "En Basese tu antecedentes Personales",
    [303046],
    19
  );
  const EcoAbdominal = CreateExamRequirements(
    'Ecografía abdominal',
    2,
    "Imagenología",
    2,
    'Ayuno 8-12hrs',
    [],
    [7],
    [],
    "En Basese tu antecedentes Personales",
    404003,
    20
  );
  const HOMA = CreateExamRequirements(
    'Índice de HOMA',
    1,
    "Laboratorio",
    2,
    'Ayuno 8-12hrs',
    [],
    [1, 7, 16],
    [],
    ["En Basese tu antecedentes Familiares","En Basese tu antecedentes Personales"],
    3030172,
    21
  );
  const Presion = CreateExamRequirements(
    'Holter de presión',
    3,
    "Procedimientos",
    1,
    'No necesita preparación',
    [],
    [7],
    [],
    "En Basese tu antecedentes Personales",
    1701009,
    22
  );
  const Hemoglobina = CreateExamRequirements(
    'Hemoglobina Glicosilada',
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [8, 9],
    [],
    "En Basese tu antecedentes Personales",
    301041,
    23
  );
  const PTGO = CreateExamRequirements(
    'PTGO (prueba de toleracia glucosa oral)',
    1,
    "Laboratorio",
    2,
    'Ayuno 8-12hrs',
    [],
    [8],
    [],
    "En Basese tu antecedentes Personales",
    302048,
    24
  );
  const Insulina = CreateExamRequirements(
    'Insulina Basal',
    1,
    "Laboratorio",
    2,
    'Ayuno 8-12hrs',
    [],
    [9],
    [],
    "En Basese tu antecedentes Personales",
    3030171,
    25
  );
  const CurvaInsulina = CreateExamRequirements(
    'Curva de Insulina',
    1,
    "Laboratorio",
    2,
    'Ayuno 8-12hrs',
    [],
    [8],
    [],
    "En Basese tu antecedentes Personales",
    303031,
    26
  );
  const Orina = CreateExamRequirements(
    'Orina Completa',
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [9, 10],
    [],
    "En Basese tu antecedentes Personales",
    309022,
    27
  );
  const RAC = CreateExamRequirements(
    'RAC (Relación albuminuria/creatinuria)',
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [9, 10],
    [],
    "En Basese tu antecedentes Personales",
    3090101,
    28
  );
  const ClearanceCreatinina = CreateExamRequirements(
    'Clearence de Creatinina',
    1,
    "Laboratorio",
    3,
    'Consultar indicaciones especiales',
    [],
    [9, 10],
    [],
    "En Basese tu antecedentes Personales",
    302024,
    29
  );
  const EcoTiroide = CreateExamRequirements(
    'Ecografía tiroídea',
    2,
    "Imagenología",
    1,
    'No necesita preparación',
    [],
    [5, 11],
    [],
    ["En Basese tu antecedentes Familiares","En Basese tu antecedentes Personales"],
    404015,
    30
  );
  const Torax = CreateExamRequirements(
    'Radiografía de Tórax AP-LAT',
    2,
    "Imagenología",
    1,
    'No necesita preparación',
    [],
    [14],
    [],
    "En Basese tu antecedentes Personales",
    401009,
    31
  );

  const CFierro = CreateExamRequirements(
    "Cinética de Fierro",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    301030,
    32
  );

  const Magnesio = CreateExamRequirements(
    "Magnesio",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    302056,
    33
  );


  const ÁcidoFólico = CreateExamRequirements(
    "Ácido Fólico",
    1,
    "Laboratorio",
    2,
    'Ayuno 8-12hrs',
    [],
    [],
    [],
    "",
    301002,
    34
  );

  const Calcio = CreateExamRequirements(
    "Calcio",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    302015,
    35
  );

  const FosfaZinc = CreateExamRequirements(
    "Fosfato de Zinc",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    111112,
    36
  );



  const Urocultivo = CreateExamRequirements(
    "Urocultivo",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    306011,
    37
  );

  const NitrogeoUrico = CreateExamRequirements(
    "Nitrógeno Ureico",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    302057,
    38
  );

  const ÁcidoUrico = CreateExamRequirements(
    "Ácido Urico",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    302005,
    39
  );


  const ElisaVIH = CreateExamRequirements(
    "Test de Elisa para VIH",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    306169,
    40
  );
  const Sífilis = CreateExamRequirements(
    "RPR Plasmático (Sífilis)",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    306038,
    41
  );

  const ElisaVHC = CreateExamRequirements(
    "Test de Elisa para VHC",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    306081,
    42
  );
  const HBsAG = CreateExamRequirements(
    "Hepatitis B, Antígeno de Superficie (HBsAG)",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    306079,
    43
  );


  const Gonotthoeae = CreateExamRequirements(
    "PCR Neisseria gonotthoeae",
    1,
    "Laboratorio",
    3,
    'Consultar indicaciones especiales',
    [],
    [],
    [],
    "",
    306016,
    44
  );


  const Trachomatis = CreateExamRequirements(
    "PCR Chlamydia trachomatis",
    1,
    "Laboratorio",
    3,
    'Consultar indicaciones especiales',
    [],
    [],
    [],
    "",
    304500,
    45
  );


  const Cultivo = CreateExamRequirements(
    "Cultivo MycoplTriglicéridosColesterol - UreaplTriglicéridosColesterol",
    1,
    "Laboratorio",
    3,
    'Consultar indicaciones especiales',
    [],
    [],
    [],
    "",
    306023,
    46
  );


  const Esfuerzo = CreateExamRequirements(
    "Test de esfuerzo",
    3,
    "Procedimientos",
    3,
    'Consultar indicaciones especiales',
    [],
    [],
    [],
    "",
    1701003,
    47
  );


  const Ferritina = CreateExamRequirements(
    "Ferritina",
    1,
    "Laboratorio",
    3,
    'Consultar indicaciones especiales',
    [],
    [],
    [],
    "",
    301026,
    48
  );

  const VDRL = CreateExamRequirements(
    "VDRL",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    306042,
    49
  );


  const Arritmia = CreateExamRequirements(
    "Holter de arritmia",
    3,
    "Procedimientos",
    3,
    'Consultar indicaciones especiales',
    [],
    [],
    [],
    "",
    1701006,
    50
  );


  const Espirometría = CreateExamRequirements(
    "Espirometría basal y con broncodilatador",
    3,
    "Procedimientos",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    1707002,
    51
  );


  const Audiometría = CreateExamRequirements(
    "Audiometría",
    3,
    "Procedimientos",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    1301021,
    52
  );

  const Par = CreateExamRequirements(
    "VIII par",
    3,
    "Procedimientos",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    1301020,
    53
  );


  const Panelabuso = CreateExamRequirements(
    "Panel de drogas de abuso",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    302137,
    54
  );

  const Anfetaminas = CreateExamRequirements(
    "Test de Anfetaminas",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    301501,
    55
  );


  const THC = CreateExamRequirements(
    "Test de THC",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    301507,
    56
  );


  const Coca = CreateExamRequirements(
    "Test de Cocaína",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    301512,
    57
  );


  const Benzodiacepinas = CreateExamRequirements(
    "Test de Benzodiacepinas",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    301502,
    58
  );

  const Anticuerpos = CreateExamRequirements(
    "Anticuerpos IGG ANTI SARS COV-2",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    3060691,
    60
  );


  const Anticuerpostotales = CreateExamRequirements(
    "Anticuerpos totales ANTI SARS COV-2 (IGG, IGM, IGA)",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    3060692,
    61
  );


  const Gonadotrofina = CreateExamRequirements(
    "Gonadotrofina Coriónica Subunidad Beta (HCG-beta) cuantitativa",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    303014,
    62
  );
  const TTG = CreateExamRequirements(
    "Anticuerpos antitransglutaminasa (TTG)",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    305181,
    63
  );


  const Inmunoglobulinas = CreateExamRequirements(
    "Inmunoglobulinas IgA, IgG, IgM, c/u",
    1,
    "Laboratorio",
    1,
    'No necesita preparación',
    [],
    [],
    [],
    "",
    305027,
    64
  );

  const CreatePacks = (
    name,
    exams, 
    //price
    //discount
    //description
    code,
    id,
  ) => {
    const Pack = {
      Name: name,
      Exams: exams,
      Code:code,
      ID: id,
    };
    return Pack;
};


const EstadoNutri = CreatePacks(
  "Estado Nutricional",
  [CFierro,Magnesio,ÁcidoFólico,Calcio,VitaminaB12,VitaminaD,FosfaZinc],
  [301030, 302056, 301002, 302015, 302077, 302078, 111112],
  0
);


const VegaVegeta = CreatePacks(
  "Vegano / Vegetariano",
  [Orina,Urocultivo,Creatinina,NitrogeoUrico,ÁcidoUrico,VitaminaB12,VitaminaD],
  [309022, 306011, 302023, 302057, 302005, 302077, 302078],
  1
);

const transmisiónsexual = CreatePacks(
  "Detección enfermedades de transmisión sexual",
  [ElisaVIH,Sífilis,ElisaVHC,HBsAG,Gonotthoeae,Trachomatis,Cultivo],
  [306169, 306038, 306081, 306079, 306016, 304500, 306023],
  2
);
const cardiovascular = CreatePacks(
  "Chequeo cardiovascular completo",
  [ElectroCardiograma,Esfuerzo,Presion],
  [1701001, 1701003, 1701009],
    3
);


const Renalorina = CreatePacks(
  "Perfil Renal y exámen de orina",
  [Orina,Urocultivo,Creatinina,NitrogeoUrico,ÁcidoUrico],
  [309022, 306011, 302023, 302057, 302005],
  4
);


const Capilar = CreatePacks(
  "Pack Capilar",
  [VitaminaB12,VitaminaD,CFierro,Ferritina,VDRL],
  [302077,302078,301030, 301026, 306042], 
  5
);



const Laboral = CreatePacks(
  "Salud Laboral",
  [Presion,Arritmia,Espirometría,Audiometría,Par],
  [1701009, 1701006, 1707002,1301021,1301020],
  6
);


const Drogas = CreatePacks(
  "Evaluación de Drogas",
  [Panelabuso,Anfetaminas,THC,Audiometría,Benzodiacepinas],
  [302137, 301501, 301507, 301512, 301502],
  7
);


const AnticuerposCovid = CreatePacks(
  "Anticuerpos Covid",
  [Anticuerpos,Anticuerpostotales],
  [3060691, 3060692],
  8
);


const testsanguíneo = CreatePacks(
  "Exámen de Embarazo (test sanguíneo)",
  [Gonadotrofina],
  [303014],
  9
);

const Celiaco = CreatePacks(
  "Pack Celiaco",
  [TTG,Inmunoglobulinas],
  [305181,305027],
  10
);

  useEffect(() => {
    localStorage.setItem("age", JSON.stringify(age));
  }, [age]);
  useEffect(() => {
    localStorage.setItem("Hemograma", JSON.stringify(Hemograma));
  }, [Hemograma]);

  useEffect(() => {
    localStorage.setItem("CProtein", JSON.stringify(CProtein));
  }, [CProtein]);

  useEffect(() => {
    localStorage.setItem("PerfilLipidico", JSON.stringify(PerfilLipidico));
  }, [PerfilLipidico]);
  useEffect(() => {
    localStorage.setItem("PerfilHepatico", JSON.stringify(PerfilHepatico));
  }, [PerfilHepatico]);
  useEffect(() => {
    localStorage.setItem("PerfilBioquimico", JSON.stringify(PerfilBioquimico));
  }, [PerfilBioquimico]);
  useEffect(() => {
    localStorage.setItem("Creatinina", JSON.stringify(Creatinina));
  }, [Creatinina]);
  useEffect(() => {
    localStorage.setItem(
      "ElectrolitosPlTriglicéridosColesterolticos",
      JSON.stringify(ElectrolitosPlTriglicéridosColesterolticos)
    );
  }, [ElectrolitosPlTriglicéridosColesterolticos]);
  useEffect(() => {
    localStorage.setItem("T4Libre", JSON.stringify(T4Libre));
  }, [T4Libre]);
  useEffect(() => {
    localStorage.setItem("TSH", JSON.stringify(TSH));
  }, [TSH]);
  useEffect(() => {
    localStorage.setItem("PAP", JSON.stringify(PAP));
  }, [PAP]);
  useEffect(() => {
    localStorage.setItem("APE", JSON.stringify(APE));
  }, [APE]);

  useEffect(() => {
    localStorage.setItem("HemorragiaOculta", JSON.stringify(HemorragiaOculta));
  }, [HemorragiaOculta]);
  useEffect(() => {
    localStorage.setItem("Ecografia", JSON.stringify(Ecografia));
  }, [Ecografia]);
  useEffect(() => {
    localStorage.setItem("Mamografia", JSON.stringify(Mamografia));
  }, [Mamografia]);
  useEffect(() => {
    localStorage.setItem("Densitometria", JSON.stringify(Densitometria));
  }, [Densitometria]);
  useEffect(() => {
    localStorage.setItem(
      "ElectroCardiograma",
      JSON.stringify(ElectroCardiograma)
    );
  }, [ElectroCardiograma]);
  useEffect(() => {
    localStorage.setItem("VitaminaD", JSON.stringify(VitaminaD));
  }, [VitaminaD]);
  useEffect(() => {
    localStorage.setItem("VitaminaB12", JSON.stringify(VitaminaB12));
  }, [VitaminaB12]);

  useEffect(() => {
    localStorage.setItem("Testosterona", JSON.stringify(Testosterona));
  }, [Testosterona]);
  useEffect(() => {
    localStorage.setItem("SHBG", JSON.stringify(SHBG));
  }, [SHBG]);
  useEffect(() => {
    localStorage.setItem("EcoAbdominal", JSON.stringify(EcoAbdominal));
  }, [EcoAbdominal]);
  useEffect(() => {
    localStorage.setItem("HOMA", JSON.stringify(HOMA));
  }, [HOMA]);
  useEffect(() => {
    localStorage.setItem("Presion", JSON.stringify(Presion));
  }, [Presion]);
  useEffect(() => {
    localStorage.setItem("Hemoglobina", JSON.stringify(Hemoglobina));
  }, [Hemoglobina]);

  useEffect(() => {
    localStorage.setItem("PTGO", JSON.stringify(PTGO));
  }, [PTGO]);
  useEffect(() => {
    localStorage.setItem("Insulina", JSON.stringify(Insulina));
  }, [Insulina]);

  useEffect(() => {
    localStorage.setItem("CurvaInsulina", JSON.stringify(CurvaInsulina));
  }, [CurvaInsulina]);
  useEffect(() => {
    localStorage.setItem("Orina", JSON.stringify(Orina));
  }, [Orina]);
  useEffect(() => {
    localStorage.setItem("RAC", JSON.stringify(RAC));
  }, [RAC]);
  useEffect(() => {
    localStorage.setItem(
      "ClearanceCreatinina",
      JSON.stringify(ClearanceCreatinina)
    );
  }, [ClearanceCreatinina]);
  useEffect(() => {
    localStorage.setItem("EcoTiroide", JSON.stringify(EcoTiroide));
  }, [EcoTiroide]);

  useEffect(() => {
    localStorage.setItem("Torax", JSON.stringify(Torax));
  }, [Torax]);

  useEffect(() => {
    localStorage.setItem(
      "ListOfAvailibleExams",
      JSON.stringify(ListOfAvailibleExams)
    );
  }, [ListOfAvailibleExams]);

  useEffect(() => {
    localStorage.setItem(
      "ListOfFamilyMedicalHistory",
      JSON.stringify(ListOfFamilyMedicalHistory)
    );
  }, [ListOfFamilyMedicalHistory]);

  useEffect(() => {
    localStorage.setItem(
      "ListOfPersonalMedicalHistory",
      JSON.stringify(ListOfPersonalMedicalHistory)
    );
  }, [ListOfPersonalMedicalHistory]);
  useEffect(() => {
    localStorage.setItem(
      "OutputListOfExams",
      JSON.stringify(OutputListOfExams)
    );
  }, [OutputListOfExams]);
  useEffect(() => {
    localStorage.setItem("Sobrepeso", JSON.stringify(Sobrepeso));
  }, [Sobrepeso]);
  useEffect(() => {
    localStorage.setItem(
      "ObesidadI",
      JSON.stringify(ObesidadI)
    );
  }, [ObesidadI]);

  useEffect(() => {
    localStorage.setItem("ObesidadII", JSON.stringify(ObesidadII));
  }, [ObesidadII]);

  useEffect(() => {
    localStorage.setItem("Hipotiroidismos", JSON.stringify(Hipotiroidismos));
  }, [Hipotiroidismos]);

  useEffect(() => {
    localStorage.setItem(
      "DisplidemiaLeve",
      JSON.stringify(DisplidemiaLeve)
    );
  }, [DisplidemiaLeve]);

  useEffect(() => {
    localStorage.setItem(
      "DisplidemiaSevera",
      JSON.stringify(DisplidemiaSevera)
    );
  }, [DisplidemiaSevera]);

  useEffect(() => {
    localStorage.setItem("GlicemiaAyunas", JSON.stringify(GlicemiaAyunas));
  }, [GlicemiaAyunas]);

  useEffect(() => {
    localStorage.setItem("Hiperuricemia", JSON.stringify(Hiperuricemia));
  }, [Hiperuricemia]);

  useEffect(() => {
    localStorage.setItem("TriglicéridosColesterol", JSON.stringify(TriglicéridosColesterol));
  }, [TriglicéridosColesterol]);

  useEffect(() => {
    localStorage.setItem("Diabético", JSON.stringify(Diabético));
  }, [Diabético]);

  useEffect(() => {
    localStorage.setItem("Tabaquismo5", JSON.stringify(Tabaquismo5));
  }, [Tabaquismo5]);

  useEffect(() => {
    localStorage.setItem("Tabaquismo10", JSON.stringify(Tabaquismo10));
  }, [Tabaquismo10]);

  useEffect(() => {
    localStorage.setItem("DiabéticoGlicemia", JSON.stringify(DiabéticoGlicemia));
  }, [DiabéticoGlicemia]);

  useEffect(() => {
    localStorage.setItem(
      "SedentaryLifeStyle",
      JSON.stringify(SedentaryLifeStyle)
    );
  }, [SedentaryLifeStyle]);

  useEffect(() => {
    localStorage.setItem("Obesity", JSON.stringify(Obesity));
  }, [Obesity]);

  useEffect(() => {
    localStorage.setItem("AllergicRhinitis", JSON.stringify(AllergicRhinitis));
  }, [AllergicRhinitis]);

  useEffect(() => {
    localStorage.setItem(
      "InsulinResistancePersonal",
      JSON.stringify(InsulinResistancePersonal)
    );
  }, [InsulinResistancePersonal]);



  useEffect(() => {
    localStorage.setItem(
      "ListOfAvailiblePacks",
      JSON.stringify(ListOfAvailiblePacks)
    );
  }, [ListOfAvailiblePacks]);

  const handleListOfPersonalMedicalHistory = (
    NewMedicalHistory,
    ChangeStateMedicalHistoryButton
  ) => {
    if (!ChangeStateMedicalHistoryButton) {
      SetListOfPersonalMedicalHistory([
        ...ListOfPersonalMedicalHistory,
        NewMedicalHistory,
      ]);
    } else {
      SetListOfPersonalMedicalHistory(
        ListOfPersonalMedicalHistory.filter(
          (Item) => Item !== NewMedicalHistory
        )
      );
    }
  };
  const handleListOfFamilyMedicalHistory = (
    NewMedicalHistory,
    ChangeStateMedicalHistoryButton
  ) => {
    if (!ChangeStateMedicalHistoryButton) {
      SetListOfFamilyMedicalHistory([
        ...ListOfFamilyMedicalHistory,
        NewMedicalHistory,
      ]);
    } else {
      SetListOfFamilyMedicalHistory(
        ListOfFamilyMedicalHistory.filter((Item) => Item !== NewMedicalHistory)
      );
    }
  };

  const ExamCases = (ListOfMedicalHistory) => {
    for (let i = 0; i < 32; i++) {
      const examinlist = ListOfAvailibleExams.find((exam) => exam.ID === i);
      handleListOfExams(
        ListOfMedicalHistory,
        examinlist.Records,
        examinlist.Name,
        examinlist.ID,
        examinlist.GenderReq,
        examinlist.Ages,
        examinlist.Emission,
        examinlist.Code,
        examinlist.Emissiontext,
        examinlist.Preparationtext,
        examinlist.Preparation,
        examinlist.Subtitulo
      );
    }
  };

  const handleListOfExams = (
    MedicalHistory,
    ExamRecords,
    ExamName,
    ExamID,
    ExamGender,
    ExamAges,
    ExamEmission,
    ExamCode,
    ExamEmissionText,
    ExamPreparationText,
    Exampreparation,
    ExamSubtitulo
  ) => {
    if (
      ExamRecords.every((elem) => MedicalHistory.includes(elem)) &&
      !(OutputListOfExams.find((exam) => exam.ID === ExamID) === undefined
        ? false
        : OutputListOfExams.find((exam) => exam.ID === ExamID).ID === ExamID)
    ) {
      SetOutputListOfExams([
        ...OutputListOfExams,
        {
          Name: ExamName,
          Ages: ExamAges,
          GenderReco: ExamGender,
          Preparation: Exampreparation,
          Preparationtext: ExamPreparationText,
          Emission: ExamEmission,
          Emissiontext: ExamEmissionText,
          Subtitulo:ExamSubtitulo,
          Code: ExamCode,
          ID: ExamID,
        },
      ]);
    }
    //var e = ExamID + 1
    // console.log(!(ListOfAvailibleExams.find(exam => exam.ID === e) === undefined ? false : ListOfAvailibleExams.find(exam => exam.ID === e).ID === e))
  };
  useEffect(() => {
    SetListOfAvailibleExams([
      Hemograma,
      CProtein,
      PerfilLipidico,
      PerfilHepatico,
      PerfilBioquimico,
      Creatinina,
      ElectrolitosPlTriglicéridosColesterolticos,
      T4Libre,
      TSH,
      PAP,
      APE,
      HemorragiaOculta,
      Ecografia,
      Mamografia,
      Densitometria,
      ElectroCardiograma,
      VitaminaD,
      VitaminaB12,
      Testosterona,
      SHBG,
      EcoAbdominal,
      HOMA,
      Presion,
      Hemoglobina,
      PTGO,
      Insulina,
      CurvaInsulina,
      Orina,
      RAC,
      ClearanceCreatinina,
      EcoTiroide,
      Torax,
    ]);
  }, []);




  useEffect(() => {
    SetListOfAvailiblePacks([
      EstadoNutri,
      VegaVegeta,
      transmisiónsexual,
      cardiovascular,
      Renalorina,
      Capilar,
      Laboral,
      Drogas,
      AnticuerposCovid,
      testsanguíneo,
      Celiaco,
    ]);
  }, []);



  const [ListOfPacks, SetListOfPacks] = useState(() => {
    const localData = localStorage.getItem("ListOfPacks");
    return localData ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    localStorage.setItem(
      "ListOfPacks",
      JSON.stringify(ListOfPacks)
    );
  }, [ListOfPacks]);



const handleListOfPacks = (Pack, ChangeState) => { 
       if ((ChangeState)) {
        SetListOfPacks([...ListOfPacks, Pack]);
        console.log(ListOfPacks)

    } else {
        SetListOfPacks(ListOfPacks.filter((Item) => Item.ID !== Pack.ID));
        console.log(ListOfPacks)

    }
  };
 


  return (
    <MedicalContext.Provider
      value={{
        handleListOfPacks,
        ListOfFamilyMedicalHistory,
        SetListOfFamilyMedicalHistory,
        ListOfPersonalMedicalHistory,
        SetListOfPersonalMedicalHistory,
        handleListOfPersonalMedicalHistory,
        handleListOfFamilyMedicalHistory,
        Sobrepeso,
        setSobrepeso,
        ObesidadI,
        setObesidadI,
        ObesidadII,
        setObesidadII,
        Hipotiroidismos,
        setHipotiroidismos,
        DisplidemiaLeve,
        setDisplidemiaLeve,
        DisplidemiaSevera,
        setDisplidemiaSevera,
        GlicemiaAyunas,
        setGlicemiaAyunas,
        Hiperuricemia,
        setHiperuricemia,
        TriglicéridosColesterol,
        setTriglicéridosColesterol,
        Diabético,
        setDiabético,
        Tabaquismo5,
        setTabaquismo5,
        Tabaquismo10,
        setTabaquismo10,
        DiabéticoGlicemia,
        setDiabéticoGlicemia,
        SedentaryLifeStyle,
        setSedentaryLifeStyle,
        Obesity,
        setObesity,
        AllergicRhinitis,
        setAllergicRhinitis,
        InsulinResistancePersonal,
        setInsulinResistancePersonal,
        ListOfAvailibleExams,
        SetListOfAvailibleExams,
        handleListOfExams,
        SetOutputListOfExams,
        OutputListOfExams,
        ExamCases,
        Hemograma,
        CProtein,
        PerfilLipidico,
        PerfilHepatico,
        PerfilBioquimico,
        Creatinina,
        ElectrolitosPlTriglicéridosColesterolticos,
        T4Libre,
        TSH,
        PAP,
        APE,
        HemorragiaOculta,
        Ecografia,
        Mamografia,
        Densitometria,
        ElectroCardiograma,
        VitaminaD,
        VitaminaB12,
        Testosterona,
        SHBG,
        EcoAbdominal,
        HOMA,
        Presion,
        Hemoglobina,
        PTGO,
        Insulina,
        CurvaInsulina,
        Orina,
        RAC,
        ClearanceCreatinina,
        EcoTiroide,
        Torax,
        age,
        setage,
        ListOfAvailiblePacks, 
        SetListOfAvailiblePacks,ListOfPacks, SetListOfPacks
      }}>
      {props.children}
    </MedicalContext.Provider>
  );
};
export default MedicalContextProvider;
