//import "@fontsource/montserrat";
import React from "react";
import "./index.css";
import Profile from "./Components/viewMedic";
import AuthMedic from "./Components/Authmedic";
//import "./i18n";
import LoginContextProvider from "./Components/Context/LoginContext";
import UserNav from "./Components/UserNav";
import FormsContextProvider from "./Components/Context/formsContext";
import { Form } from "react-router-dom";
import MedicalContextProvider from "./Components/Context/MedicalContext";
//import ContextProvider from "./Components/Context/Context"; aquí importarían su context provider

const MedicPage = () => {
  return (
    <>
    <LoginContextProvider>
      <React.StrictMode>
        <FormsContextProvider>
        <MedicalContextProvider>
      <AuthMedic/>
      <UserNav />
      <Profile/>
      </MedicalContextProvider>
      </FormsContextProvider>
      </React.StrictMode>
      </LoginContextProvider>
    </>
  );
};

export default MedicPage;
