import { useState, createContext, useEffect } from "react";

export const FinalExamsContext = createContext();

const FinalExamsContextProvider = (props) => {
  //lista a entregar a pdf
  const [FinalOutputListOfExams, SetFinalOutputListOfExams] = useState(() => {
    const localData = localStorage.getItem("FinalOutputListOfExams");
    return localData ? JSON.parse(localData) : [];
  });

  //Lista de exámenes seleccionados
  const [ListOfExamsChosen, SetListOfExamsChosen] = useState(() => {
    const localData = localStorage.getItem("ListOfExamsChosen");
    return localData ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    localStorage.setItem(
      "ListOfExamsChosen",
      JSON.stringify(ListOfExamsChosen)
    );
  }, [ListOfExamsChosen]);

  useEffect(() => {
    localStorage.setItem(
      "FinalOutputListOfExams",
      JSON.stringify(FinalOutputListOfExams)
    );
  }, [FinalOutputListOfExams]);

  const handleListOfExamsChosen = (Exam, ChangeState) => {
    if (ChangeState) {
      SetListOfExamsChosen([...ListOfExamsChosen, Exam]);
    } else {
      SetListOfExamsChosen(ListOfExamsChosen.filter((Item) => Item !== Exam));
    }
  };

  const addpacks = (packs) => {
    for (const pack of packs) {
        for (const examinpack of pack.Exams) {
          if (ListOfExamsChosen.find((exam) => exam.Code === examinpack.Code) === undefined) {
            console.log(ListOfExamsChosen,examinpack)
            SetListOfExamsChosen([...ListOfExamsChosen, examinpack])
            console.log(ListOfExamsChosen)
          }         
        }         
      }
  
  };

  return (
    <FinalExamsContext.Provider
      value={{
        addpacks,
        ListOfExamsChosen,
        SetListOfExamsChosen,
        handleListOfExamsChosen,
        FinalOutputListOfExams,
        SetFinalOutputListOfExams,
      }}>
      {props.children}
    </FinalExamsContext.Provider>
  );
};
export default FinalExamsContextProvider;
