import { useState, createContext, useEffect } from "react";
import React from "react";

export const LoginContext = createContext();

const LoginContextProvider = (props) =>{
    const [User, setUser] = useState(() => {
        const localData = localStorage.getItem("User");
        return localData ? JSON.parse(localData) : [];
      });


      const [Pasword, setPasword] = useState(() => {
        const localData = localStorage.getItem("Pasword");
        return localData ? JSON.parse(localData) : [];
      });



      const PaswordUser = ["user"];
      const PaswordMedic = ["medic"];
      const PaswordToma = ["decision"];

      const RutdUser = ["20416852-0"];
      const RutdMedic = ["11816836-4"];
      const RutdToma = ["20541605-6"];

      const [validador,setvalidador] = useState(() => {
        const localData = localStorage.getItem("validador");
        return localData ? JSON.parse(localData) : false; });

        const [error,seterrorr] = useState(() => {
          const localData = localStorage.getItem("error");
          return localData ? JSON.parse(localData) : true; });


      useEffect(() => {
        localStorage.setItem("User", JSON.stringify(User));
      }, [User]);


      useEffect(() => {
        localStorage.setItem("Pasword", JSON.stringify(Pasword));
      }, [Pasword]);

    
      useEffect(() => {
        localStorage.setItem("PaswordUser", JSON.stringify(PaswordUser));
      }, [PaswordUser]);
      useEffect(() => {
        localStorage.setItem("PaswordMedic", JSON.stringify(PaswordMedic));
      }, [PaswordMedic]);
      useEffect(() => {
        localStorage.setItem("PaswordToma", JSON.stringify(PaswordToma));
      }, [PaswordToma]);

      useEffect(() => {
        localStorage.setItem("RutdUser", JSON.stringify(RutdUser));
      }, [RutdUser]);
      useEffect(() => {
        localStorage.setItem("RutdMedic", JSON.stringify(RutdMedic));
      }, [RutdMedic]);
      useEffect(() => {
        localStorage.setItem("RutdToma", JSON.stringify(RutdToma));
      }, [RutdToma]);

      useEffect(() => {
        localStorage.setItem("validador", JSON.stringify(validador));
      }, [validador]);

      useEffect(() => {
        localStorage.setItem("error", JSON.stringify(error));
      }, [error]);


      return(
        <LoginContext.Provider value={{
            User,
            setUser,
            Pasword, setPasword,PaswordUser,PaswordMedic,PaswordToma,RutdUser,RutdMedic,RutdToma,validador,setvalidador,error,seterrorr
        }}>
                  {props.children}
        </LoginContext.Provider>
      );
};
export default LoginContextProvider;
