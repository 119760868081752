//import "@fontsource/montserrat";
import React from "react";
import "./index.css";
import App from "./Components/Dolor";
import UserNav from "./Components/UserNav";
import "bootstrap/dist/css/bootstrap.min.css";
import MedicalContextProvider from "./Components/Context/MedicalContext";
import "bootstrap/dist/css/bootstrap.min.css";
import FormsContextProvider from "./Components/Context/formsContext";
import AuthUser from "./Components/Authuser";
import LogContextProvider from "./Components/Context/LoginContext";
import FinalExamsContextProvider from "./Components/Context/FinalExamsContext";
const ThankYouPage = () => {
  return (
    <>
      <React.StrictMode>
        <FinalExamsContextProvider>
          <FormsContextProvider>
            <MedicalContextProvider>
              <LogContextProvider>
                <AuthUser />
                <UserNav />
                <App />
              </LogContextProvider>
            </MedicalContextProvider>
          </FormsContextProvider>
        </FinalExamsContextProvider>
      </React.StrictMode>
    </>
  );
};

export default ThankYouPage;
