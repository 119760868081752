import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import "./Styles/Login.css";
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useContext, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import { LoginContext } from "./Context/LoginContext";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { User, setUser, Pasword, setPasword, PaswordUser, PaswordMedic, PaswordToma, RutdUser, RutdMedic, RutdToma, validador, setvalidador ,error,seterrorr} = useContext(LoginContext);
  const Navigate = useNavigate();



   function checkRut(rut,errorr) {
     seterrorr(true)
       // Despejar Puntos
       var valor = rut.value.replace(".", "");
       // Despejar GuiÃ³n
   valor = valor.replace("-", "");

    // Aislar Cuerpo y DÃ­gito Verificador
     var cuerpo = valor.slice(0, -1);
    var dv = valor.slice(-1).toUpperCase();
    // Formatear RUN
     rut.value = cuerpo + "-" + dv;

  // Si no cumple con el mÃ­nimo ej. (n.nnn.nnn)
       if (rut.value === "-") {
           rut.value= ""
           setvalidador(false)
         return false;
       }

       if (cuerpo.length < 7) {
           rut.setCustomValidity("Invalid field.");
           console.log(rut.checkValidity())
           setvalidador(false)
           return false;
         }

     // Calcular DÃ­gito Verificador
     var suma = 0;
     var multiplo = 2;

     // Para cada dÃ­gito del Cuerpo
      for (let i = 1; i <= cuerpo.length; i++) {
        // Obtener su Producto con el MÃºltiplo Correspondiente
      var index = multiplo * valor.charAt(cuerpo.length - i);

      // Sumar al Contador General
     suma = suma + index;

     // Consolidar MÃºltiplo dentro del rango [2,7]
      if (multiplo < 7) {
        multiplo = multiplo + 1;
        } else {
       multiplo = 2;
      }
    }

    // Calcular DÃ­gito Verificador en base al MÃ³dulo 11
   var dvEsperado = 11 - (suma % 11);

    // Casos Especiales (0 y K)
   dv = dv == "K" ? 10 : dv;
    dv = dv == 0 ? 11 : dv;

    // Validar que el Cuerpo coincide con su DÃ­gito Verificador
    if (dvEsperado != dv) {
   rut.setCustomValidity("Invalid field.");
      console.log(rut.checkValidity())
      setvalidador(false)
     return false;
    }

     // Si todo sale bien, eliminar errores (decretar que es vÃ¡lido)
     rut.setCustomValidity("");
     setvalidador(true)
    console.log(rut.checkValidity())

   }


        const Paswordsender = (value,Rut) =>{
            if (PaswordUser.includes(value)&& (RutdUser.includes(Rut))) {
              setvalidador(1)
              seterrorr(true)
              setTimeout(() => {
                Navigate("/Profile");
              }, 200);
            }
             else if (PaswordMedic.includes(value)&& (RutdMedic.includes(Rut))){
              setvalidador(2)
              seterrorr(true)
             setTimeout(() => {
              Navigate("/Medico");
            }, 200);
           }   
           else if (PaswordToma.includes(value)&& (RutdToma.includes(Rut))){
            setvalidador(3)
            seterrorr(true)
             setTimeout(() => {
              Navigate("/Admin");
            }, 200);
            }
            else {
              setvalidador(0)
              seterrorr(false)
            }
      }
    

  return (
    <section >
      <Container className='logincontainer'>
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={6} xl={5}>
            <Card className='CardLogin'>
              <Card.Body className='CardBodyLogin'>
                <div className="mb-md-5 mt-md-4 pb-5">
                  <h2>INICIA SESIÓN</h2>
                  <p className="message">Por favor ingrese su contraseña</p>
                  <Form>
                    { <Form.Group className="RutForm">
                      <Form.Control
                        type="text"
                        size='sm'
                        id="rut"
                        name="rut"
                        required
                        value={User}
                        onChangeCapture={(e) => checkRut(e.target)}
                        onChange={(e)=>setUser(e.target.value)}
                        placeholder=" "
                        className="form-control-login form-control"
                      />
                      <Form.Label className="form-label-login FormRut">Rut</Form.Label>
                    </Form.Group> }
                    <Form.Group className="passwordForm">
                      <Form.Control
                        type="password"
                        id="typePasswordX"
                        placeholder=" "
                        size='sm'
                        className="form-control-login form-control"
                        value={Pasword}
                        onChange={(e) => setPasword(e.target.value)}
                      />
                      <Form.Label className="form-label-login FormPassword">Contraseña</Form.Label>
                    </Form.Group>
                             {/*     <p class="small mb-5 pb-lg-2"><a class="text-white-50" href="#!">Forgot password?</a></p>*/}
         {!error ? (
 
 <Alert variant="danger" onClose={() => seterrorr(true)} dismissible>

                        <p className='ErrorRut'>
                          El usuario o la contraseña ingresada no coincide con los registros en el sitio web. Por favor contacte al Administrador del Sistema.
                        </p>
                      </Alert>
                    ) : null}
                    <Button className="loginbutton" onClick={(e) => Paswordsender(Pasword, User)}>Login</Button>
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Login;