import {React, useState} from 'react';
import { Stack, Col, Row, Modal, Table, Form, Button, Breadcrumb, BreadcrumbItem, ModalBody, ListGroup, ListGroupItem } from 'react-bootstrap';
import "./Styles/Admin.css";
import CardiacGraph from "./CardiacGraph";
import AnyChart from 'anychart-react'
import anychart from 'anychart'
import { useEffect } from 'react';

const CreateReasonsInter = (
  reason,
  number,
  numeric,
  id
) => {
  const ReasonsInter = {
    Reason: reason,
    Number: number,
    RealNum: numeric,
    ID: id
  };

  return ReasonsInter;
};

const Nutricion = CreateReasonsInter(
  'Nutrición',
  "12.000",
  12000,
  1
);
const MallaGeneral = CreateReasonsInter(
  'Malla General Exámenes',
  "8.342",
  8342,
  2
);
const CurvaInsulina = CreateReasonsInter(
  'Curva Insulina',
  "3.550",
  3550,
  3
);
const ToleranciaGlucosa = CreateReasonsInter(
  'Test tolerancia glucosa',
  "11.596",
  11596,
  4
);
const PoliObesidad = CreateReasonsInter(
  'Poli Obesidad',
  "2.932",
  2932,
  5
);
const Creatinina = CreateReasonsInter(
  'Creatinina',
  "10.680",
  10680,
  6
);
const MedicinaGeneral = CreateReasonsInter(
  'Medicina General',
  "5.680",
  5680,
  7
);
const DraPalacios = CreateReasonsInter(
  'Dra Palacios',
  "1.503",
  1503,
  8
);
const CeseTabaquismo = CreateReasonsInter(
  'Cese Tabaquismo',
  "11.900",
  11900,
  9
);
const RxTorax = CreateReasonsInter(
  'Rx Torax',
  "7.582",
  7582,
  10
);
const ListInterconsults = [Nutricion, MallaGeneral, CurvaInsulina, ToleranciaGlucosa, PoliObesidad,
  Creatinina, MedicinaGeneral, DraPalacios, CeseTabaquismo, RxTorax]


function DivWidth() {
  
  for(var i=1;i<11;i++){
    const divOne = document.getElementById(i)
    divOne.style.width = ListInterconsults[i-1].RealNum / 120 + '%';
    console.log(ListInterconsults[i-1].RealNum/ 120)
    //console.log(12000 / ListInterconsults[i-1].RealNum)
    //divOne.style.width = 12000/num 
  }
}

function Barchart() {
  // set the data
  var data = {
    header: ['Name', 'Death toll'],
    rows: [
      ['20-29', 1573],
      ['30-39', 2050],
      ['40-49', 1230],
      ['50-59', 900],
    ]};

  // create the chart
  var chart = anychart.column();

  // add data
  chart.data(data);

  // set the chart title
  chart.title('');
  chart.yAxis().title('Nº de Funcionarios');

  // draw
  chart.container('container3');
  chart.draw();

  return chart
}

function PieChart() {
    // create data set
    var data = anychart.data.set([
      ['Normal', 115200],
      ['Sobrepeso', 35850],
      ['Obesidad', 75900]
    ]);

    // create pie chart with passed data
    var chart = anychart.pie(data);

    // set chart radius
    chart
      .innerRadius('65%')
      // set value for the exploded slices
      .explode(25);

    // create standalone label and set settings
    var label = anychart.standalones.label();
    label
      .enabled(true)
      .text('Total Funcionarios\n25.243')
      .width('100%')
      .height('100%')
      .adjustFontSize(true, true)
      .minFontSize(10)
      .maxFontSize(25)
      .fontColor('#60727b')
      .position('center')
      .anchor('center')
      .hAlign('center')
      .vAlign('middle')
      .minFontSize(15);

    // set label to center content of chart
    chart.center().content(label);

    // create range color palette with color ranged
    var palette = anychart.palettes.rangeColors();
    palette.items([{ color: '#0D6DB7' }, { color: '#00B0BC' }]);
    // set chart palette
    chart.palette(palette);

    // set hovered settings
    chart.hovered().fill('#17307a');

    // set selected settings
    chart.selected().fill('#0D43B7');

    // set hovered outline settings
    chart
      .hovered()
      .outline()
      .fill(function () {
        return anychart.color.lighten('#34566f', 0.55);
      });

    // set selected outline settings
    chart
      .selected()
      .outline()
      .offset(5)
      .fill(function () {
        return anychart.color.lighten('#34566f', 0.25);
      });

    chart.legend().position('right').itemsLayout('vertical');

    // set container id for the chart
    chart.container('container2');
    // initiate chart drawing
    chart.draw();
    return chart
  
}


const AdminPanel = () => {
  useEffect(() => {
    DivWidth();
  }, []);

  const [iframeShow, setiframeShow] = useState(false);

  return (
 <div className="AllAdmin">
  <Modal
   size="lg"
   show={iframeShow}
   onHide={() => setiframeShow(false)} backdrop="static" keyboard={false}>

        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
   
    <ModalBody className="px-5">
      <div className='iframeDiv px-4' id="staticBackdropLabel"> 
        <iframe id="iFrame" width="640px" height="480px" 
        src = "https://forms.office.com/Pages/ResponsePage.aspx?id=NsUZdQTGWUOZb85-CdAjNmmdEYUjpExKswoh7IrdXvJUOVVLTU44WkZXRVZQRk0zV1NaTVNZTEdKRy4u&embed=true"
        frameborder="0" marginwidth="0" marginheight="0" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen></iframe>
      </div>
    </ModalBody>
   
  </Modal>
   
  <Row className='bodyAdmin' gap={3}>
  <span> PANEL DE ADMINISTRACIÓN DE MEDICINA PREVENTIVA </span>
  </Row>
  <Row lg={3} className='AdminRow1 g-3'>
    <Col lg={4}>
      <div className='cardAdmin1 rounded-5 border'>
        <span>Envejecimiento cardiaco promedio <span className='Bluey'>+10</span> años de envejecimiento</span>
        <div className='Graphdiv'>
          <CardiacGraph id="container"/>
        </div>
      </div>
    </Col>

    <Col lg={6}>
    <div className='cardAdmin2 rounded-5 border'>
        <span className='MotivesInter'>Motivos de Interconsulta</span>
        <div className='HorizonLineAttent2'></div>
        {ListInterconsults.map((list) => (
        <Stack direction="horizontal" gap={3} className='ReasonsStack'>
        <span>{list.Reason}</span>
        <span className="ms-auto">{list.Number}</span>
        <div className='QuantityBars'>
          <div className='QuantityBars2' id={list.ID} ></div>
        </div>
        </Stack>
))}
      </div>
    </Col>
    
    <Col lg={2}>
    <Stack className='cardAdmin3 rounded-5 border'>
    <div className='Col3Stuff'>
        <span className='AttentionMes'>Atenciones este mes</span>
        <div className='HorizonLineAttent'></div>
        <Stack direction="horizontal" gap={3}>
        <span className='AttentionN1'>2.523</span>
        <span className='AttentionSidetext'>Atenciones de medicina preventiva</span>
        </Stack>
        <div className='HorizonLineAttent'></div>
        <Stack direction="horizontal" gap={3}>
        <span className='AttentionN2'>+50%</span>
        <span className='AttentionSidetext'>Requiere interconsulta</span>
        </Stack>
        <div className='HorizonLineAttent'></div>
        <Stack direction="horizontal" gap={3}>
        <span className='AttentionN3'>+80%</span>
        <span className='AttentionSidetext'>Consultas cerradas</span>
        </Stack>
        </div>
      </Stack>
      
    </Col>
  </Row>
  <Row lg={3} className='AdminRow1 g-3'>
    <Col lg={6}>
      <div className='cardAdmin1 rounded-5 border'>
        <span className='ObesityTitle'>Funcionarios con Obesidad por edad</span>
        <div id="container3">
        {useEffect(() => {
        const chart2 = PieChart();

        // Return a cleanup function to remove the chart when the component unmounts
        return () => chart2.dispose();
        }, [])}
        </div>
      </div>
    </Col>
    
    <Col lg={6}>
    <Stack className='cardAdmin3 rounded-5 border'>
    <div className='Col5Stuff'>
        <span className='ObesityTitle'>Funcionarios con Obesidad por Edad</span>
        <div id="container2">
        {useEffect(() => {
        const chart3 = Barchart();

        // Return a cleanup function to remove the chart when the component unmounts
        return () => chart3.dispose();
        }, [])}
        </div>
    </div>
      </Stack>
    </Col>
  </Row>

  
  <div className='cardAdmin5 rounded-5 border'>
    <Stack direction="horizontal" gap={3}>
      <Stack direction="horizontal" className='StackGeneralInfo'>
        <span>Todos &#40;25.000&#41;</span>
        <div className='VerticLineInfo'></div> 
        <span>Aptos &#40;20.000&#41;</span>
        <div className='VerticLineInfo'></div>
        <span>Aptos C/O &#40;3.000&#41;</span>
        <div className='VerticLineInfo'></div>
        <span>No Apto &#40;2.000&#41;</span>
      </Stack>
      <Form className='ms-auto'>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control type="rut" placeholder="Ingresar Rut" className='rutAdminInput border border-dark' />
        </Form.Group>
      </Form>
      <Button className='UserSearch border-0'>
      BUSCAR PACIENTE
      </Button>
    </Stack>
    <Stack direction="horizontal" gap={3}>
      <Form className='ms-auto'>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control type="ProfileType" placeholder="Filtrar por perfil" className='rutAdminInput border border-dark' />
        </Form.Group>
      </Form>
      <Form className='ms-auto'>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control type="Repart" placeholder="Filtrar por repartición" className='rutAdminInput border border-dark' />
        </Form.Group>
      </Form>
      <Form className='ms-auto'>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control type="Servicio" placeholder="Filtrar por servicio" className='rutAdminInput border border-dark' />
        </Form.Group>
      </Form>
      <Button className='ExportSelection ms-auto border-0'>
      EXPORTAR SELECCIÓN
      </Button>
    </Stack>
    <Table striped className='TableInfo border'>
      <thead>
        <tr>
          <td>Nombre</td>
          <td>Rut</td>
          <td>Mail</td>
          <td>Edad</td>
          <td>IMC</td>
          <td>R. Cardiovascular</td>
          <td>S. Metabólico</td>
          <td>Perfil</td>
        </tr>
      </thead>
      <tbody>
      <tr>
          <td>Juan Pérez</td>
          <td>20416852-0</td>
          <td>jperez@ejemplo.cl</td>
          <td>38</td>
          <td>35.8</td>
          <td>7.3</td>
          <td>SÍ</td>
          <td>No Apto</td>
        </tr>
      </tbody>
    </Table>
  </div>
    
  <Button variant="primary" className='OpenForms rounded-circle' onClick={() => setiframeShow(true)}>?</Button>   
</div>
  );
};

export default AdminPanel;