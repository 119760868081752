//import "@fontsource/montserrat";
import React from "react";
import "./index.css";
import AdminPanel from "./Components/viewAdmin";
import AuthToma from "./Components/Auth toma";
//import "./i18n";
import LoginContextProvider from "./Components/Context/LoginContext";
import UserNav from "./Components/UserNav";
//import ContextProvider from "./Components/Context/Context"; aquí importarían su context provider

const AdminPage = () => {
  return (
    <>
    <LoginContextProvider>
      <React.StrictMode>
        <AuthToma/>
        <UserNav />
        <AdminPanel/>
      </React.StrictMode>
     </LoginContextProvider>
    </>
  );
};

export default AdminPage;
