import "./Styles/UserNav.css";
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar';
import { LoginContext } from "./Context/LoginContext";
import Nav from 'react-bootstrap/Nav';
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Accordion from 'react-bootstrap/Accordion';
import {useContext, useState } from "react";
//import { useTranslation, Trans } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Sticky from 'react-stickynode';

function hero() {
    var navi = document.getElementById("menu");
    var check = document.getElementById("menu-toggle");
    if(check.checked == true) {
        navi.style.marginLeft = '0px';
    } else {
        navi.style.marginLeft = '-300px';
    }
}

function UserNav() {
    const { validador, setvalidador } = useContext(LoginContext);
    History = useNavigate();
    const Logout = () =>{
        setvalidador(0);
        setTimeout(() => {
            History("/");
          }, 200);
    }
       

    //const { t } = useTranslation();
    return (
        <Sticky enabled={true} bottomBoundary={1200}>
        <div className="Navigation">
            <div className="NavbarUsuario">
                <Navbar expand="sm" className="Navbar" id="Navbar">
                    {/* <Container>
                        <div className="divSideBar" onClick={hero}>
                        <input type="checkbox" className="ToggleSB" id="menu-toggle"/>
                        <label id="trigger" for="menu-toggle" className="checkburg"></label>
                        <label for="menu-toggle" id="burger" className="checkburg"></label>
                        </div>
                        
                    </Container> */}

                    <Button className="LogoutButton" onClick={()=>Logout()}> Cerrar sesión </Button>
                </Navbar>
            </div>
            <div class="flex-shrink-0 p-3 bg-white" className="SideBar" id="menu">
            <Link to="/User">
                <div className="MainPageButton border rounded-0">
                    Página Principal
                </div>
                </Link>
                                <Accordion className="mb-1" alwaysOpen defaultActiveKey={['0', '1', '2']}>
                                    <Accordion.Item eventKey="0" className="rounded-0">
                                        <Accordion.Header>
                                            Medicina Preventiva
                                        </Accordion.Header>
                                        <Accordion.Body className="collapse show" id="home-collapse">
                                            <Stack gap={3} className="btn-toggle-nav list-unstyled">
                                                <li><a href="#" className="link-dark">Exámenes</a></li>
                                                <li><a href="#" className="link-dark">Indicadores</a></li>
                                                <li><a href="#" className="link-dark">Vacunas</a></li>
                                                <li><a href="#" className="link-dark">Encuestas</a></li>
                                            </Stack >
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" className="rounded-0">
                                        <Accordion.Header>
                                            Atenciones
                                        </Accordion.Header>
                                        <Accordion.Body className="collapse show" id="dashboard-collapse">
                                            <Stack gap={3} className="btn-toggle-nav list-unstyled">
                                                <li><a href="#" className="link-dark">Citaciones</a></li>
                                                <li><a href="#" className="link-dark">Historial</a></li>
                                                <li><a href="#" className="link-dark">Informes</a></li>
                                                <li><a href="#" className="link-dark">Cargas Familiares</a></li>
                                            </Stack>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" className="rounded-0">
                                        <Accordion.Header>
                                            Datos de Contacto
                                        </Accordion.Header>
                                        <Accordion.Body className="collapse show" id="orders-collapse">
                                            <Stack gap={3} className="btn-toggle-nav list-unstyled">
                                                <li><a href="#" className="link-dark">Teléfono / Email</a></li>
                                            </Stack>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                        </div>
            
        </div>
        </Sticky>
    );
}

export default UserNav;