import React from "react";
import { useState, createContext, useEffect } from "react";

export const FormsContext = createContext();

const FormsContextProvider = (props) => {
  const [Name, setName] = useState(() => {
    const localData = localStorage.getItem("Name");
    return localData ? JSON.parse(localData) : [];
  });

  const [Gender, setGender] = useState(() => {
    const localData = localStorage.getItem("Gender");
    return localData ? JSON.parse(localData) : [];
  });

  const [email, setemail] = useState(() => {
    const localData = localStorage.getItem("email");
    return localData ? JSON.parse(localData) : [];
  });

  const [secondName, setsecondName] = useState(() => {
    const localData = localStorage.getItem("secondName");
    return localData ? JSON.parse(localData) : [];
  });

  const [DateBirth, setDateBirth] = useState(() => {
    const localData = localStorage.getItem("DateBirth");
    return localData ? JSON.parse(localData) : [];
  });
  const [Rutificador, setRutificador] = useState(() => {
    const localData = localStorage.getItem("Rutificador");
    return localData ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    localStorage.setItem("Gender", JSON.stringify(Gender));
  }, [Gender]);

  useEffect(() => {
    localStorage.setItem("Name", JSON.stringify(Name));
  }, [Name]);
  useEffect(() => {
    localStorage.setItem("email", JSON.stringify(email));
  }, [email]);
  useEffect(() => {
    localStorage.setItem("secondName", JSON.stringify(secondName));
  }, [secondName]);

  useEffect(() => {
    localStorage.setItem("DateBirth", JSON.stringify(DateBirth));
  }, [DateBirth]);
  useEffect(() => {
    localStorage.setItem("Rutificador", JSON.stringify(Rutificador));
  }, [Rutificador]);

  return (
    <FormsContext.Provider
      value={{
        Name,
        setName,
        secondName,
        setsecondName,
        DateBirth,
        setDateBirth,
        Rutificador,
        setRutificador,
        email,
        setemail,
        Gender,
        setGender,
      }}>
      {props.children}
    </FormsContext.Provider>
  );
};
export default FormsContextProvider;
