import FormsContextProvider from "./Components/Context/formsContext";
import MedicalContextProvider from "./Components/Context/MedicalContext";
import FinalExamsContextProvider from "./Components/Context/FinalExamsContext";
import UserNav from "./Components/UserNav";
import Reasons from "./Components/Reasons";
import React from "react";
import AuthUser from "./Components/Authuser";
import LogContextProvider from "./Components/Context/LoginContext";
import Cards from "./Components/cards";

const ReasonsPage = () => {
  return (
    <>
      <React.StrictMode>
      <FinalExamsContextProvider>
        <FormsContextProvider>
          <MedicalContextProvider>
            <LogContextProvider>
              <AuthUser />
              <UserNav />
              <Reasons />
             <Cards /> 
            </LogContextProvider>
          </MedicalContextProvider>
        </FormsContextProvider>
        </FinalExamsContextProvider>
      </React.StrictMode>
    </>
  );
};

export default ReasonsPage;
