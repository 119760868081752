import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Styles/personal.css";
import Button from "react-bootstrap/Button";
import "./Styles/butts.css";
import { useState, useContext, useEffect } from "react";
import ToggleButton from "react-bootstrap/ToggleButton";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import { Link, useNavigate } from "react-router-dom";
import { MedicalContext } from "./Context/MedicalContext";


function HistoryButtonsPersonal() {
  const {
    ListOfFamilyMedicalHistory,
    ListOfPersonalMedicalHistory,
    handleListOfPersonalMedicalHistory,
    Sobrepeso,
    setSobrepeso,
    ObesidadI,
    setObesidadI,
    ObesidadII,
    setObesidadII,
    Hipotiroidismos,
    setHipotiroidismos,
    DisplidemiaLeve,
    setDisplidemiaLeve,
    DisplidemiaSevera,
    setDisplidemiaSevera,
    GlicemiaAyunas,
    setGlicemiaAyunas,
    Hiperuricemia,
    setHiperuricemia,
    TriglicéridosColesterol,
    setTriglicéridosColesterol,
    Tabaquismo5,
    setTabaquismo5,
    Tabaquismo10,
    setTabaquismo10,
    Diabético,
    setDiabético,
    DiabéticoGlicemia,
    setDiabéticoGlicemia,
    SetOutputListOfExams,
    ExamCases,
  } = useContext(MedicalContext);
  const history = useNavigate();

  const ListOfMedicalHistory = ListOfFamilyMedicalHistory.concat(
    ListOfPersonalMedicalHistory
  );

  const SaveList = (ListOfMedicalHistory) => {
    ExamCases(ListOfMedicalHistory);
  };

  const [isExploding, setIsExploding] = React.useState(true);

  const holdtheline = () => {
    SetOutputListOfExams([]);
    setTimeout(() => {
      history("/FHistorialMedico");
    }, 200);
  };

  useEffect(() => {
    SaveList(ListOfMedicalHistory);
  }, [ListOfPersonalMedicalHistory]);

  return (
    <>
 
    <div className="AllBacks">
  
      <div className="cards">
   
        <ThemeProvider
          breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
          minBreakpoint="xxs">
          <Row xxs={1} md={3} xl={3}>
            <Col className="cos" key="0">
              <div className="ToggleButtonsIFPage">
                <ToggleButton
                  size="lg"
                  className="ToggleButtonIFPage"
                  id="butt0"
                  type="checkbox"
                  variant="outline-primary"
                  checked={Sobrepeso}
                  value="0"
                  onChange={(e) => setSobrepeso(e.currentTarget.checked)}
                  onClick={() =>
                    handleListOfPersonalMedicalHistory(1, Sobrepeso)
                  }>
                  Sobrepeso
                </ToggleButton>
              </div>

              <div className="ToggleButtonsIFPage">
                <ToggleButton
                  size="lg"
                  className="ToggleButtonIFPage"
                  id="butt1"
                  type="checkbox"
                  variant="outline-primary"
                  checked={ObesidadI}
                  value="0"
                  onChange={(e) => setObesidadI(e.currentTarget.checked)}
                  onClick={() =>
                    handleListOfPersonalMedicalHistory(2, ObesidadI)
                  }>
                  Obesidad I
                </ToggleButton>
              </div>

              <div className="ToggleButtonsIFPage">
                <ToggleButton
                  size="lg"
                  className="ToggleButtonIFPage"
                  id="butt2"
                  type="checkbox"
                  variant="outline-primary"
                  checked={ObesidadII}
                  value="0"
                  onChange={(e) => setObesidadII(e.currentTarget.checked)}
                  onClick={() =>
                    handleListOfPersonalMedicalHistory(3, ObesidadII)
                  }>
                  Obesidad II
                </ToggleButton>
              </div>
            </Col>

            <Col className="cos" key="1">
              <div className="ToggleButtonsIFPage">
                <ToggleButton
                  size="lg"
                  className="ToggleButtonIFPage"
                  id="butt3"
                  type="checkbox"
                  variant="outline-primary"
                  checked={Hipotiroidismos}
                  value="0"
                  onChange={(e) => setHipotiroidismos(e.currentTarget.checked)}
                  onClick={() =>
                    handleListOfPersonalMedicalHistory(4, Hipotiroidismos)
                  }>
                  Hipotiroidismos
                </ToggleButton>
              </div>

              <div className="ToggleButtonsIFPage">
                <ToggleButton
                  size="lg"
                  className="ToggleButtonIFPage"
                  id="butt4"
                  type="checkbox"
                  variant="outline-primary"
                  checked={DisplidemiaLeve}
                  value="0"
                  onChange={(e) => setDisplidemiaLeve(e.currentTarget.checked)}
                  onClick={() =>
                    handleListOfPersonalMedicalHistory(5, DisplidemiaLeve)
                  }>
                  Displidemia Leve
                </ToggleButton>
              </div>

              <div className="ToggleButtonsIFPage">
                <ToggleButton
                  size="lg"
                  className="ToggleButtonIFPage"
                  id="butt5"
                  type="checkbox"
                  variant="outline-primary"
                  checked={DisplidemiaSevera}
                  value="0"
                  onChange={(e) => setDisplidemiaSevera(e.currentTarget.checked)}
                  onClick={() =>
                    handleListOfPersonalMedicalHistory(6, DisplidemiaSevera)
                  }>
                  Displidemia Severa
                </ToggleButton>
              </div>
            </Col>

            <Col className="cos" key="2">
              <div className="ToggleButtonsIFPage">
                <ToggleButton
                  size="lg"
                  className="ToggleButtonIFPage"
                  id="butt6"
                  type="checkbox"
                  variant="outline-primary"
                  checked={GlicemiaAyunas}
                  value="0"
                  onChange={(e) => setGlicemiaAyunas(e.currentTarget.checked)}
                  onClick={() =>
                    handleListOfPersonalMedicalHistory(7, GlicemiaAyunas)
                  }>
                  Glicemia en Ayunas Alterada
                </ToggleButton>
              </div>

              <div className="ToggleButtonsIFPage">
                <ToggleButton
                  size="lg"
                  className="ToggleButtonIFPage"
                  id="butt7"
                  type="checkbox"
                  variant="outline-primary"
                  checked={Hiperuricemia}
                  value="0"
                  onChange={(e) => setHiperuricemia(e.currentTarget.checked)}
                  onClick={() =>
                    handleListOfPersonalMedicalHistory(8, Hiperuricemia)
                  }>
                  Hiperuricemia
                </ToggleButton>
              </div>
              
              <div className="ToggleButtonsIFPage">
                <ToggleButton
                  size="lg"
                  className="ToggleButtonIFPage"
                  id="butt8"
                  type="checkbox"
                  variant="outline-primary"
                  checked={TriglicéridosColesterol}
                  value="0"
                  onChange={(e) => setTriglicéridosColesterol(e.currentTarget.checked)}
                  onClick={() =>
                    handleListOfPersonalMedicalHistory(9, TriglicéridosColesterol)
                  }>
                  Triglicéridos y Colesterol &gt; 300mg/dl
                </ToggleButton>
              </div>
              </Col>
          </Row>
          <Row>
            <Col>
              <div className="ToggleButtonsIFPage">
                <ToggleButton
                  size="lg"
                  className="ToggleButtonIFPage"
                  id="butt9"
                  type="checkbox"
                  variant="outline-primary"
                  checked={Tabaquismo5}
                  value="0"
                  onChange={(e) => setTabaquismo5(e.currentTarget.checked)}
                  onClick={() =>
                    handleListOfPersonalMedicalHistory(10, Tabaquismo5)
                  }>
                  Tabaquismo &gt; 5 Cigarrillos Diarios
                </ToggleButton>
              </div>
            </Col>
            <Col>
              <div className="ToggleButtonsIFPage">
                <ToggleButton
                  size="lg"
                  className="ToggleButtonIFPage"
                  id="butt10"
                  type="checkbox"
                  variant="outline-primary"
                  checked={Tabaquismo10}
                  value="0"
                  onChange={(e) => setTabaquismo10(e.currentTarget.checked)}
                  onClick={() =>
                    handleListOfPersonalMedicalHistory(11, Tabaquismo10)
                  }>
                  Tabaquismo &gt; 10 Cigarrillos Diarios
                </ToggleButton>
              </div>
            </Col>
          </Row>
        </ThemeProvider>
      </div>
      <div className="cards">
        <Row>
          <div>
            <div>
              <h4 className="Backgrounds">INGRESA TUS ANTECEDENTES FAMILIARES</h4>
            </div>
          </div>
        </Row>

        <ThemeProvider
          breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
          minBreakpoint="xxs">
          <Row xxs={1} md={3} xl={3} className="FamilyMatters">
            <Col className="cos" key="4">
              <div className="ToggleButtonsIFPage">
                <ToggleButton
                  size="lg"
                  className="ToggleButtonIFPage"
                  id="butt11"
                  type="checkbox"
                  variant="outline-primary"
                  checked={Diabético}
                  value="0"
                  onChange={(e) => setDiabético(e.currentTarget.checked)}
                  onClick={() =>
                    handleListOfPersonalMedicalHistory(11, Diabético)
                  }>
                  Diabético
                </ToggleButton>
              </div>
            </Col>
            <Col>
              <div className="ToggleButtonsIFPage">
                <ToggleButton
                  size="lg"
                  className="ToggleButtonIFPage"
                  id="butt12"
                  type="checkbox"
                  variant="outline-primary"
                  checked={DiabéticoGlicemia}
                  value="0"
                  onChange={(e) => setDiabéticoGlicemia(e.currentTarget.checked)}
                  onClick={() =>
                    handleListOfPersonalMedicalHistory(11, DiabéticoGlicemia)
                  }>
                  Diabético + Glicemia alterada
                </ToggleButton>
              </div>
            </Col>
          </Row>
        </ThemeProvider>
      </div>

    </div>
    </>
  );
}

export default HistoryButtonsPersonal;
