import React from "react";
import './Styles/cards.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import {  useContext ,useEffect} from "react";
import { MedicalContext } from "./Context/MedicalContext";
import { FinalExamsContext } from "./Context/FinalExamsContext";
import "bootstrap/dist/css/bootstrap.min.css";
import {  Row, Col, Card} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import CardHeader from "react-bootstrap/esm/CardHeader";


function Cards() {
    const {
        addpacks,
        } = useContext(FinalExamsContext);
 const {
    
    handleListOfPacks,
    ListOfAvailiblePacks,
    ListOfPacks
    } = useContext(MedicalContext);


    const path = useNavigate();

    const holdtheline = (packs) => {
        setTimeout(() => {
          path("/loading");
        }, 200);
      };
        
    
return (
    <>
    <div class="AllCards">
      <div className="ExamsHere">
        <div>
        <Row className="Packs" lg={3}>
          {ListOfAvailiblePacks.map((list) => (
          
              <Card className="m-2 PackCard">
                <CardHeader>
                    <Card.Title>{list.Name}</Card.Title>
                </CardHeader>
            
               <Card.Body>
                   {list.Exams.map((exam) => (
                    <ul>
                       <li> {exam.Name} </li>
                    </ul>
                  ))}
               </Card.Body>
               <Card.Footer class="ButtonsPack">
                
               <Form.Check
                  inline
                  defaultChecked={false}
                  label={list.id}
                  name="group1"
                  type="checkbox"
                  id={`inline-'checkbox'-1`}
                  className="CheckboxExams"
                  onChange={(e) =>
                    handleListOfPacks(list, e.target.checked)
                  }

                />
               </Card.Footer>
             </Card>
         
        ))}</Row>
      </div>
        
          
    </div>
                <Row className="NextBeforeButtons">
                    <Col>
                        <Link to='/Ingresa'>
                            <Button
                              className="Butt1 rounded-pill"
                              size="lg"
                              variant="outline-primary">
                              Atrás
                            </Button>
                        </Link>
                    </Col>
                    <Col >
                            <Button
                                className="Butt2 rounded-pill"
                                size="lg"
                                variant="primary"
                                onClick={()=>holdtheline(ListOfPacks)}>
                               Generar orden
                            </Button>
                    
                    </Col>
                </Row>
        </div>   </>
  );
    
}

export default Cards;