//import "@fontsource/montserrat";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { MedicalContext } from "./Context/MedicalContext";
import { FinalExamsContext } from "./Context/FinalExamsContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import { FormsContext } from "./Context/formsContext";
import "./Styles/Reasons.css";

const Reasons = () => {
  const {
    OutputListOfExams,
    SetOutputListOfExams,
    age,
    setage,
    ListOfFamilyMedicalHistory,
    ListOfPersonalMedicalHistory,
    ExamCases,
  } = useContext(MedicalContext);
  const {
    handleListOfExamsChosen,
    SetListOfExamsChosen,
    ListOfExamsChosen,
    
  } = useContext(FinalExamsContext);
  const {
    Name,
    setName,
    secondName,
    setsecondName,
    DateBirth,
    setDateBirth,
    Rutificador,
    setRutificador,
    email,
    setemail,
    Gender,
    setGender,
  } = useContext(FormsContext);
  let Exam = OutputListOfExams.map((object) => {
    let Persona = object.ID;
    return Persona;
  });

  const GenderLocked = () => {
    for (let id of Exam) {
      //console.log(id);
      const examinlist = OutputListOfExams.find((exam) => exam.ID === id);
      if (Number.isInteger(Gender)) {
        if (!(examinlist.GenderReco.length === 0)) {
          console
            .log
            //    examinlist.GenderReco,
            //    Gender,
            //    examinlist.GenderReco.includes(Gender)
            ();
          if (!examinlist.GenderReco.includes(Gender) && !(Gender === 3)) {
            SetOutputListOfExams(
              OutputListOfExams.filter((Item) => Item.ID !== id)
            );
          }
        }
      }
    }
  };

  const agelock = () => {
    for (let id of Exam) {
      const examinlist = OutputListOfExams.find((exam) => exam.ID === id);
      if (Number.isInteger(age)) {
        if (examinlist.Ages > age) {
          SetOutputListOfExams(
            OutputListOfExams.filter((Item) => Item.ID !== id)
          );
        }
      }
    }
  };

  const cancercolo = () => {
    for (let id of Exam) {
      const examinlist = OutputListOfExams.find((exam) => exam.ID === id);
      if (examinlist.ID === 11) {
        if (Gender === 1) {
          if (age < 45) {
            SetOutputListOfExams(
              OutputListOfExams.filter((Item) => Item.ID !== id)
            );
          }
        } else if (Gender === 2) {
          if (age < 65) {
            SetOutputListOfExams(
              OutputListOfExams.filter((Item) => Item.ID !== id)
            );
          }
        }
      }
    }
  };

  const Electrocardio = () => {
    for (let id of Exam) {
      const examinlist = OutputListOfExams.find((exam) => exam.ID === id);
      if (examinlist.ID === 15) {
        if (Gender === 1) {
          if (age < 45) {
            SetOutputListOfExams(
              OutputListOfExams.filter((Item) => Item.ID !== id)
            );
          }
        } else if (Gender === 2) {
          if (age < 65) {
            SetOutputListOfExams(
              OutputListOfExams.filter((Item) => Item.ID !== id)
            );
          }
        }
      }
    }
  };

  const mamariaeco = () => {
    for (let id of Exam) {
      const examinlist = OutputListOfExams.find((exam) => exam.ID === id);
      if (examinlist.ID === 12) {
        if (!(34 < age && age < 40)) {
          SetOutputListOfExams(
            OutputListOfExams.filter((Item) => Item.ID !== id)
          );
        }
      }
    }
  };

  useEffect(() => {
    GenderLocked();
    agelock();
    cancercolo();
    mamariaeco();
    Electrocardio();
  });
  useEffect(() => {
    SetListOfExamsChosen(OutputListOfExams);
  }, [OutputListOfExams]);
  let usernamesArr = OutputListOfExams.map((object) => {
    let username = `${object.Name}`;
    return username;
  });
  return (
    <>
    <div className="progress-4"></div>
    <div className="ExamsHere">
      <div className="ExamsTitle">
        <span>Seleccione los exámenes que desee realizar</span>
      </div>
      <div className="row row-cols-3">
        {OutputListOfExams.map((list) => (
          <div
            className="blob-preview p-2 border rounded-3"
            id="cardItem"
            key={list.id}>
            <Stack direction="horizontal" gap={3}>
              <div>
                <Form.Check
                  inline
                  defaultChecked={true}
                  label={list.id}
                  name="group1"
                  type="checkbox"
                  id={`inline-'checkbox'-1`}
                  onChange={(e) =>
                    handleListOfExamsChosen(list, e.target.checked)
                  }
                  className="CheckboxExams"
                />
              </div>
              <div>
                <div>
                  <span className="SpanNames">{list.Name}</span>
                </div>
                <span className="SubtextExams">{list.Subtitulo}</span>
                <div>
                  <span className="SubtextExams">{list.Preparationtext}</span>
                </div>
              </div>
            </Stack>
          </div>
        ))}
      </div>
     
    </div>
    </>
  );
};

export default Reasons;
