import {React, useState} from 'react';
import { Container, Stack, Col, Modal, Row, Card, Button, Breadcrumb, BreadcrumbItem, ListGroup, ListGroupItem, ModalTitle, ModalBody, ModalFooter } from 'react-bootstrap';
import "./Styles/UserProfile.css";
import App from "./Dolor";
import Forms from "./forms";
import FormsContextProvider from "./Context/formsContext";
import MedicalContextProvider from "./Context/MedicalContext";
import LogContextProvider from "./Context/LoginContext";
import HistoryButtonsPersonal from "./PersonalMedicalHistory";
import Interconsultas from "./Interconsultas";
import FinalExamsContextProvider from "./Context/FinalExamsContext";
import PFP from "./Photos/Profilepic.png"
import check from "./Photos/check.png"

const Profile = () => {

   const [FormsShow, setFormsShow] = useState(false);
   const [MedicalShow, setMedicalShow] = useState(false);
   const [interconsultasShow, setinterconsultasShow] = useState(false);
   const [PDFshow, setPDFShow] = useState(false);
   const [iframeShow, setiframeShow] = useState(false);
   
   const handleCloseForms = () => {
    setFormsShow(false);
    setMedicalShow(true)
   }
  
   const handleCloseMedical = () => {
    setMedicalShow(false)
    setinterconsultasShow(true)
   }
  
   return (
 <div className="AllUserContain">

  {/* inicio modal pdf */}
  <Modal
   size="lg"
   show={PDFshow}
   onHide={() => setPDFShow(false)}>
   
    <ModalBody className="px-5">
    <FinalExamsContextProvider>
          <FormsContextProvider>
            <MedicalContextProvider>
              <LogContextProvider>
                <App />
              </LogContextProvider>
            </MedicalContextProvider>
          </FormsContextProvider>
        </FinalExamsContextProvider>
    </ModalBody>
   
  </Modal>
  {/* fin modal pdf */}

  {/* inicio modal forms */}
  <Modal
   size="lg"
   show={FormsShow}
   onHide={() => setFormsShow(false)}>
    <ModalTitle     className="pt-4 FormsTitle">   
     INGRESE SU INFORMACIÓN
    </ModalTitle>
    <ModalBody className="px-5">
      <MedicalContextProvider>
        <FormsContextProvider>
          <Forms/>
        </FormsContextProvider>
      </MedicalContextProvider>
    </ModalBody>
    <ModalFooter className="border-white">
      <Button 
      className="Butt2 rounded-pill" 
      size="lg" 
      onClick={()=>handleCloseForms()}
      variant="primary">
        Continuar
      </Button>
    </ModalFooter>
  </Modal>
  {/* fin modal forms */}

  {/* inicio modal Medical history */}
  <Modal
   size="lg"
   show={MedicalShow}
   onHide={() => setMedicalShow(false)}>
    <ModalTitle     className="pt-4 FormsTitle">   
   INGRESA TUS ANTECEDENTES PERSONALES
    </ModalTitle>
    <ModalBody className="px-5">
    <FormsContextProvider>
        <MedicalContextProvider>
          <HistoryButtonsPersonal />
        </MedicalContextProvider>
      </FormsContextProvider>
    </ModalBody>
    <ModalFooter className="border-white">
      <Button 
      className="Butt2 rounded-pill" 
      size="lg" 
      onClick={()=>handleCloseMedical()}
      variant="primary">
        Continuar
      </Button>
    </ModalFooter>
  </Modal>
{/* fin modal Medical history */}

  {/* inicio modal interconsultas */}
  <Modal
   size="lg"
   show={interconsultasShow}
   onHide={() => setinterconsultasShow(false)}>
    <ModalTitle     className="pt-4 FormsTitle">   
    Interconsultas Pendientes
    </ModalTitle>
    <ModalBody className="px-5 BodyInter">
    <FormsContextProvider>
        <MedicalContextProvider>
          <Interconsultas />
        </MedicalContextProvider>
      </FormsContextProvider>
    </ModalBody>
    <ModalFooter className="border-white">
      <Button 
      className="Butt2 rounded-pill" 
      size="lg" 
      onClick={()=>setinterconsultasShow(false)}
      variant="primary">
        Cerrar
      </Button>
    </ModalFooter>
  </Modal>
{/* fin modal interconsultas */}

{/* inicio modal iframe */}
<Modal
   size="lg"
   show={iframeShow}
   onHide={() => setiframeShow(false)} backdrop="static" keyboard={false}>

        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
   
    <ModalBody className="px-5">
      <div className='iframeDiv px-4' id="staticBackdropLabel"> 
        <iframe id="iFrame" width="640px" height="480px" 
        src = "https://forms.office.com/Pages/ResponsePage.aspx?id=NsUZdQTGWUOZb85-CdAjNmmdEYUjpExKswoh7IrdXvJUOVVLTU44WkZXRVZQRk0zV1NaTVNZTEdKRy4u&embed=true"
        frameborder="0" marginwidth="0" marginheight="0" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen></iframe>
      </div>
    </ModalBody>
   
  </Modal>
  {/* fin modal iframe */}



      <Row>
        <Stack direction="horizontal" className='StackButtonUser' gap={3}>
          <Button onClick={() => setPDFShow(true)} size="lg" className='GenerateCons border-0 rounded-2 ms-auto'>GENERAR ORDEN MÉDICA</Button> 
          <Button onClick={() => setFormsShow(true)} size="lg" className='AddInterCons border-0 rounded-2'>EDITAR ANTECEDENTES PERSONALES</Button> 
        </Stack>
       </Row>
       <Row className='UserRow1 g-3'>
       <Col>
       <div className='cardUserProf1 rounded-4 border'>
          <Stack className='UserProfStack1' direction="horizontal">
            <img src={PFP} className='PFPImage'/>
            <div className='BasicProfileInfo'>
              <div className='UserNameDiv'>
                <span className='UserName'>Juan Pérez</span>
              </div>
              <Stack className='BasicProfileInfoStack'>
                <span className='UserIdent'>Rut: <span className='bluey'>20.416.852-0</span></span>
                <span className='UserIdent'>Edad: <span className='bluey'>38 años</span></span>
                <span className='UserIdent'>Sexo: <span className='bluey'>Masculino</span></span>
              </Stack>
            </div>
            <div className='NextApp ms-auto'>
              <Stack>
                <span className=''>Próximo Control:</span>
                <span className=''>04/09/2023</span>
              </Stack>
            </div>
            <div className='ProfileBox rounded-3 border border-info'>
              <span className='BoxOfHistory'>Antecedentes Personales</span>
              <Stack className='UserProfStack1_1' direction="horizontal" gap={3}>
                <span className='HistoryHighlight rounded-2'>Diabetes</span>
                <span className='HistoryHighlight rounded-2'>Tabaquismo</span>
              </Stack>
            </div>
            <div className='ProfileBox rounded-3 border border-info'>
              <span className='BoxOfHistory'>Antecedentes Familiares</span>
              <Stack className='UserProfStack1_1' direction="horizontal" gap={3}>
                <span className='HistoryHighlight rounded-2'>Obesidad</span>
              </Stack>
            </div>
         </Stack>
         </div>
         </Col>
       </Row>
       <Row lg={2} className='AdminRow1 g-2'>
        
        <Col lg={5}>
          <div className='cardUserProf3 rounded-4 border'>
            <div className='LastEvalBox rounded-3 border border-info'>
              <Stack direction="horizontal" className='p-2'>
                <img src={check} className='CheckIconStack'/>
                <Stack className='UltEvStack'>
                  <span className='UltEv1'>Ultima evaluación</span>
                  <span className='UltEv2'>NO APTO</span>
                  <span className='UltEv1'><span className='bluey'>9 meses</span> desde la última atención</span>
                </Stack>
              </Stack>
            </div>
            <Stack direction="horizontal" className='IMCStack p-2 rounded'>
              <span className=''>IMC:</span>
              <span className='ms-auto'>OBESIDAD II</span>
            </Stack>
            <div>
              <Stack direction="horizontal" className='DateControl'>
                <span className='DateItself ms-auto'>Control Anterior:<br/>04/05/2023</span>
                <span className='DateItself'>Último Control:<br/>04/07/2023</span>
              </Stack>
              <Stack direction="horizontal" className='PhysDataStack p-2 rounded'>
                <span className=''>Peso:</span>
                <div className='Greenery ms-auto rounded-3'>
                  <span>127 kg</span>
                </div>
                <div className='Greenery rounded-3'>
                  <span>120 kg</span>
                </div>
              </Stack>
              <Stack direction="horizontal" className='PhysDataStack p-2 rounded'>
                <span className=''>Talla:</span>
                <div className='Greenery ms-auto rounded-3'>
                  <span>183</span>
                </div>
                <div className='Greenery rounded-3'>
                  <span>183</span>
                </div>
              </Stack>
              <Stack direction="horizontal" className='PhysDataStack p-2 rounded'>
                <span className=''>% Grasa:</span>
                <div className='Greenery ms-auto rounded-3'>
                  <span>33.5</span>
                </div>
                <div className='Greenery rounded-3'>
                  <span>30.8</span>
                </div>
              </Stack>
              <Stack direction="horizontal" className='PhysDataStack p-2 rounded'>
                <span className=''>Cintura:</span>
                <div className='Greenery ms-auto rounded-3'>
                  <span>125</span>
                </div>
                <div className='Greenery rounded-3'>
                  <span>120</span>
                </div>
              </Stack>
              <Stack direction="horizontal" className='PhysDataStack p-2 rounded'>
                <span className=''>Presión Arterial</span>
                <div className='Greenery ms-auto rounded-3'>
                  <span>165/98</span>
                </div>
                <div className='Greenery rounded-3'>
                  <span>155/98</span>
                </div>
              </Stack>
            </div>
          </div>
        </Col>
        <Col lg={7}>
          <div className='cardUserProf4 rounded-4 border p-4'>
            <div className='RiskIndicators'>
              <span>Indicadores de Riesgo</span>
            </div>
            <Stack direction="horizontal" className='HeaderTags p-2'>
              <span className='RiskTag1 ms-auto'>Último Control</span>
              <span className='RiskTag2'>Control Actual</span>
              <span className='RiskTag3'>Rango Óptimo</span>
            </Stack>
            <div className='RiskIndicatorsBox rounded-4 p-3'>
              <Stack direction="horizontal" className='RiskTable p-2'>
                <Stack className='RiskTableHead'>
                  <span className='RiskSpan1'>Índice Aterogénico Plasmático</span>
                  <span className='RiskSpan2'>Hace 3 semanas</span>
                </Stack>
                <span className='RiskSpan3 ms-auto'>180</span>
                <span className='RiskSpan4'>180</span>
                <span className='RiskSpan5'>180</span>
              </Stack>
              <div className='horizontalDivider'></div>
              <Stack direction="horizontal" className='RiskTable p-2'>
                <Stack className='RiskTableHead'>
                  <span className='RiskSpan1'>Colesterol Total Venoso</span>
                  <span className='RiskSpan2'>Hace 1 año</span>
                </Stack>
                <span className='RiskSpan3 ms-auto'>95</span>
                <span className='RiskSpan4'>95</span>
                <span className='RiskSpan5'>95</span>
              </Stack>
              <div className='horizontalDivider'></div>
              <Stack direction="horizontal" className='RiskTable p-2'>
                <Stack className='RiskTableHead'>
                  <span className='RiskSpan1'>Glicemia</span>
                  <span className='RiskSpan2'>Hace 1 año</span>
                </Stack>
                <span className='RiskSpan3 ms-auto'>30</span>
                <span className='RiskSpan4'>30</span>
                <span className='RiskSpan5'>30</span>
              </Stack>
              <div className='horizontalDivider'></div>
              <Stack direction="horizontal" className='RiskTable p-2'>
                <Stack className='RiskTableHead'>
                  <span className='RiskSpan1'>Colesterol HDL</span>
                  <span className='RiskSpan2'>Hace 1 año</span>
                </Stack>
                <span className='RiskSpan3 ms-auto'>70</span>
                <span className='RiskSpan4'>70</span>
                <span className='RiskSpan5'>70</span>
              </Stack>
            </div>
          </div>
        </Col>
       </Row>

       <Button variant="primary" className='OpenForms rounded-circle' onClick={() => setiframeShow(true)}>?</Button>
</div>
  );
};

export default Profile;