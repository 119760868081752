//import "@fontsource/montserrat";
import React from "react";
import "./index.css";
import UserNav from "./Components/UserNav";
import UserData from "./Components/UserData";
//import Barsup from "./Components/barSup";
//import Footer from "./Components/Fotter";
//import "./i18n";
import Button from 'react-bootstrap/Button';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import AnyChart from 'anychart-react'
import MedicalContextProvider from "./Components/Context/MedicalContext";
//import ContextProvider from "./Components/Context/Context"; aquí importarían su context provider
import LogContextProvider from "./Components/Context/LoginContext";
import FormsContextProvider from "./Components/Context/formsContext";

const UserPage = () => {
  return (
    <>
          <React.StrictMode>
<FormsContextProvider>
            <MedicalContextProvider>
              <LogContextProvider>
              <UserNav/>
       <UserData />
              </LogContextProvider>
            </MedicalContextProvider>
          </FormsContextProvider>
      </React.StrictMode>
    </>
  );
};

export default UserPage;