import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import "./Styles/Interconsultas.css";


const Interconsultas = () => {
    return (
    <>
    <div className="ExamsHere">
    
      <div class="col-xs-1" align="center">
          <div
            className="CheckStacks blob-preview p-2 border rounded-3"
            id="cardItem"
            key="1">
            <Stack gap={3}>
              <div>
                <Form.Check
                  inline
                  defaultChecked={false}
                  label="Nutrición"
                  name="group1"
                  type="checkbox"
                  id="13"
                  className="CheckboxExams"
                />
              </div>
            </Stack>
          </div>
          <div
            className="CheckStacks blob-preview p-2 border rounded-3"
            id="cardItem"
            key="1">
            <Stack gap={3}>
              <div>
                <Form.Check
                  inline
                  defaultChecked={false}
                  label="Cese Tabaquismo"
                  name="group1"
                  type="checkbox"
                  id="14"
                  className="CheckboxExams"
                />
              </div>
            </Stack>
          </div>
          <div
            className="CheckStacks blob-preview p-2 border rounded-3"
            id="cardItem"
            key="1">
            <Stack gap={3}>
              <div>
                <Form.Check
                  inline
                  defaultChecked={false}
                  label="GGTP"
                  name="group1"
                  type="checkbox"
                  id="15"
                  className="CheckboxExams"
                />
              </div>
            </Stack>
          </div>
          <div
            className="CheckStacks blob-preview p-2 border rounded-3"
            id="cardItem"
            key="1">
            <Stack gap={3}>
              <div>
                <Form.Check
                  inline
                  defaultChecked={false}
                  label="T3, TSH, T4 Libre"
                  name="group1"
                  type="checkbox"
                  id="16"
                  className="CheckboxExams"
                />
              </div>
            </Stack>
          </div>
          
      </div>
     
    </div>
    </>
  );
};

export default Interconsultas;