import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./Styles/forms.css";
import "./Styles/butts.css";
import { useState, useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import { FormsContext } from "./Context/formsContext";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { MedicalContext } from "./Context/MedicalContext";

import { Stack } from "react-bootstrap";

function Forms() {
  let alerta = document.getElementById("alerta");
  let mensaje = document.getElementById("mensaje");

 

  const {
    Name,
    setName,
    secondName,
    setsecondName,
    DateBirth,
    setDateBirth,
    Rutificador,
    setRutificador,
    email,
    setemail,
    Gender,
    setGender,
  } = useContext(FormsContext);

  const {
    OutputListOfExams,
    SetOutputListOfExams,
    age,
    setage,
    ListOfFamilyMedicalHistory,
    ListOfPersonalMedicalHistory,
    ExamCases,
  } = useContext(MedicalContext);

  const [Day, setDay] = useState("");
  const [startDatemonth, setStartDatemonth] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());

  const history = useNavigate();
useEffect(()=>{
setage(38);
setName("Juan");
setsecondName("Pérez");
setGender("1");
setDay(17);

},[])

  const Useholdtheline = () => {
    setDateBirth(startDate.getFullYear());
    console.log(startDate)
    setTimeout(() => {
      history("/Reasons");
    }, 500);
  };

  const ListOfMedicalHistory = ListOfFamilyMedicalHistory.concat(
    ListOfPersonalMedicalHistory
  );

  const SaveList = (ListOfMedicalHistory) => {
    ExamCases(ListOfMedicalHistory);
    //SetOutputListOfExams([]);
    //SetListOfPersonalMedicalHistory([]);
  };
  useEffect(() => {
    SaveList(ListOfMedicalHistory);
  });
  return (
    <> 
    
    <Form className="Bod">
      
      <Row md="2" xs="1">
        <Form.Group as={Col} className="mb-3 fGroup">
          <Form.Label>NOMBRE</Form.Label>
          <Form.Control
            className="fControl border"
            type="text"
            placeholder="Nombre"
            readOnly
            value={Name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group as={Col} className="mb-3 fGroup">
          <Form.Label>APELLIDO</Form.Label>
          <Form.Control
            className="fControl border"
            type="text"
            placeholder="Apellido"
            readOnly
            value={secondName}
            onChange={(e) => setsecondName(e.target.value)}
          />
        </Form.Group>
      </Row>
      <Row md="3" xs="1">
        <Form.Group as={Col} className="mb-2 fGroupMonth">
          <Form.Label>FECHA DE NACIMIENTO</Form.Label>

          <Form.Select
            aria-label="Default select example"
            type="text"
            className="daySelector"
            value={Day}
            disabled
            onChange={(e) => setDay(e.target.value)}>
            <option> </option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="31">31</option>
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} className="sm-1 fGroupMonth">
          <Form.Label></Form.Label>
          <DatePicker
            selected={startDatemonth}
            onChange={(date) => setStartDatemonth(date)}
            dateFormat="MM"
            value="11"
            disabled
            showMonthYearPicker
            className="monthPick rounded border"
          />
        </Form.Group>

        <Form.Group as={Col} className="sm-1 fGroup">
          <Form.Label></Form.Label>
          <DatePicker 
            selected={startDate}
            value="1985"
            onChange={(date) => {
              setage(new Date().getFullYear()- date.getFullYear(), setStartDate(date));
            }}
            showYearPicker
            disabled
            dateFormat="yyyy"
            className="yearPick rounded border"
          />
        </Form.Group>

        <Form.Group as={Col} className="sm-1 fGroup">
          <Form.Label>GÉNERO</Form.Label>
          <Form.Select
            value={Gender}
            disabled
            className="genderSelector"
            onChange={(e) => setGender(Number(e.target.value))}>
            <option> </option>
            <option value={1}>Masculino</option>
            <option value={2}>Femenino</option>
            <option value={3}>Otro</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row md="2" xs="1">
     
        <Form.Group
          as={Col}
          className="sm-1 fGroupRUT">
          <Form.Label>Altura</Form.Label>
          <Stack direction="horizontal">
          <Form.Control
          readOnly
          value="178"
             className="RUTinput border"
            type="number"
            placeholder='Altura'
            onChange={(e) => setRutificador(e.target.value)}
          />
          <InputGroup.Text>cm</InputGroup.Text></Stack>
        </Form.Group>
        <Form.Group
          as={Col}
          className="sm-1 fGroupEmail"
          controlId="formBasicEmail">
          <Form.Label>Peso</Form.Label>
          <Stack direction="horizontal">
          <Form.Control
          readOnly
          value="80"
            className="fControl border"
            type="number"
            placeholder='Peso'
            onChange={(e) => setemail(e.target.value)}
          />
          <InputGroup.Text>Kg</InputGroup.Text></Stack>
        </Form.Group>
      </Row>
      
    </Form>
    </>
  );
}

export default Forms;
