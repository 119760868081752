import React from 'react';
import AnyChart from 'anychart-react'
import anychart from 'anychart'
import { useEffect } from 'react';

function CardiacGraph() {
    // create data set on our data
    var dataSet = anychart.data.set(getData());

    // map data for the first series, take x from the zero column and value from the first column of data set
    var firstSeriesData = dataSet.mapAs({ x: 0, value: 1 });

    // map data for the second series, take x from the zero column and value from the second column of data set
    var secondSeriesData = dataSet.mapAs({ x: 0, value: 2 });

    // create line chart
    var chart = anychart.line();

    // turn on chart animation
    chart.animation(true);

    // set chart padding
    chart.padding([10, 20, 5, 20]);

    // turn on the crosshair
    chart.crosshair().enabled(true).yLabel(false).yStroke(null);

    // set tooltip mode to point
    chart.tooltip().positionMode('point');
    chart.container("container");
    // set chart title text settings
    chart.title(
      ''
    );

    // set yAxis title
    chart.yAxis().title('Kilogramos');
    chart.xAxis().labels().padding(5);

    // create first series with mapped data
    var firstSeries = chart.line(firstSeriesData);
    firstSeries.name('Proyección actual');
    firstSeries.hovered().markers().enabled(true).type('circle').size(4);
    firstSeries
      .tooltip()
      .position('right')
      .anchor('left-center')
      .offsetX(5)
      .offsetY(5);

    // create second series with mapped data
    var secondSeries = chart.line(secondSeriesData);
    secondSeries.name('Regresión lineal');
    secondSeries.hovered().markers().enabled(true).type('circle').size(4);
    secondSeries
      .tooltip()
      .position('right')
      .anchor('left-center')
      .offsetX(5)
      .offsetY(5);

    // turn the legend on
    chart.legend().enabled(true).fontSize(13).padding([0, 0, 30, 0]);

    // set container id for the chart
    chart.container('container');
    // initiate chart drawing
    chart.draw()
    return chart
}

const DoneCardiacGraph = () => {
    return (<div id="container">
        {useEffect(() => {
        const chart = CardiacGraph();

        // Return a cleanup function to remove the chart when the component unmounts
        return () => chart.dispose();
        }, [])}
        </div>);
  };

  function getData() {
    return [
      ['04/12/2022', 135, 135, 1, 1],
      ['04/01/2023', 130, 132, 4.1, 14.1],
      ['04/03/2023', 129, 129, 5.1, 17.5],
      ['04/05/2023', 127, 126, 6.5, 18.9],
      ['04/07/2023', 120, 123, 12.5, 20.8],
      ['04/09/2023', 115, 120, 18.0, 22.9],
    ];
  }

  export default DoneCardiacGraph;