import {React, useState ,useContext,useEffect} from 'react';
import { Stack, Row, Col, Modal, Card, Button, Breadcrumb, Table, BreadcrumbItem, ModalTitle, ModalBody, ModalFooter } from 'react-bootstrap';
import "./Styles/PErfil.css";
import CardiacGraph from "./CardiacGraph";
import Forms from "./forms";
import FormsContextProvider from "./Context/formsContext";
import MedicalContextProvider from "./Context/MedicalContext";
import HistoryButtonsPersonal from "./PersonalMedicalHistory";
import Interconsultas from "./Interconsultas";
import 'bootstrap/dist/css/bootstrap.min.css';
import sobre from "./Photos/sobrepeso.png";
import check from "./Photos/check.png";
import cruz from "./Photos/cruz.png";
import cora from "./Photos/cora.png";
import { MedicalContext } from "./Context/MedicalContext";
import { FormsContext } from "./Context/formsContext";

const PatientProfile = () => {

   const [FormsShow, setFormsShow] = useState(false);
   const [MedicalShow, setMedicalShow] = useState(false);
   const [interconsultasShow, setinterconsultasShow] = useState(false);
   const [iframeShow, setiframeShow] = useState(false);
   
   const handleCloseForms = () => {
    setFormsShow(false);
    setMedicalShow(true)
   }
  
   const handleCloseMedical = () => {
    setMedicalShow(false)
    setinterconsultasShow(true)
   }
  
   const {
    Name,
    setName,
    secondName,
    setsecondName,
    DateBirth,
    setDateBirth,
    Rutificador,
    setRutificador,
    email,
    setemail,
    Gender,
    setGender,
  } = useContext(FormsContext);

  const {
    OutputListOfExams,
    SetOutputListOfExams,
    age,
    setage,
    ListOfFamilyMedicalHistory,
    ListOfPersonalMedicalHistory,
    ExamCases,
  } = useContext(MedicalContext);


  useEffect(()=>{
    setage(38);
    setName("Juan");
    setsecondName("Pérez");
    setGender("1");
    
    },[])



   return (
    <div style={{ backgroundColor: '#e0f3f4' }} className=" ContainerUser">

    {/* inicio modal forms */}
    <Modal
     size="lg"
     show={FormsShow}
     onHide={() => setFormsShow(false)}>
      <ModalTitle     className="pt-4 FormsTitle">   
       INGRESE SU INFORMACIÓN
      </ModalTitle>
      <ModalBody className="px-5">
        <MedicalContextProvider>
          <FormsContextProvider>
            <Forms/>
          </FormsContextProvider>
        </MedicalContextProvider>
      </ModalBody>
      <ModalFooter className="border-white">
        <Button 
        className="Butt2 rounded-pill" 
        size="lg" 
        onClick={()=>handleCloseForms()}
        variant="primary">
          Continuar
        </Button>
      </ModalFooter>
    </Modal>
    {/* fin modal forms */}
  
    {/* inicio modal Medical history */}
    <Modal
     size="lg"
     show={MedicalShow}
     onHide={() => setMedicalShow(false)}>
      <ModalTitle     className="pt-4 FormsTitle">   
     INGRESA TUS ANTECEDENTES PERSONALES
      </ModalTitle>
      <ModalBody className="px-5">
      <FormsContextProvider>
          <MedicalContextProvider>
            <HistoryButtonsPersonal />
          </MedicalContextProvider>
        </FormsContextProvider>
      </ModalBody>
      <ModalFooter className="border-white">
        <Button 
        className="Butt2 rounded-pill" 
        size="lg" 
        onClick={()=>handleCloseMedical()}
        variant="primary">
          Continuar
        </Button>
      </ModalFooter>
    </Modal>
  {/* fin modal Medical history */}
  
    {/* inicio modal interconsultas */}
    <Modal
     size="lg"
     show={interconsultasShow}
     onHide={() => setinterconsultasShow(false)}>
      <ModalTitle     className="pt-4 FormsTitle">   
      Interconsultas Pendientes
      </ModalTitle>
      <ModalBody className="px-5 BodyInter">
      <FormsContextProvider>
          <MedicalContextProvider>
            <Interconsultas />
          </MedicalContextProvider>
        </FormsContextProvider>
      </ModalBody>
      <ModalFooter className="border-white">
        <Button 
        className="Butt2 rounded-pill" 
        size="lg" 
        onClick={()=>setinterconsultasShow(false)}
        variant="primary">
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  {/* fin modal interconsultas */}
  <Modal
   size="lg"
   show={iframeShow}
   onHide={() => setiframeShow(false)} backdrop="static" keyboard={false}>

        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
   
    <ModalBody className="px-5">
      <div className='iframeDiv px-4' id="staticBackdropLabel"> 
        <iframe id="iFrame" width="640px" height="480px" 
        src = "https://forms.office.com/Pages/ResponsePage.aspx?id=NsUZdQTGWUOZb85-CdAjNmmdEYUjpExKswoh7IrdXvJUOVVLTU44WkZXRVZQRk0zV1NaTVNZTEdKRy4u&embed=true"
        frameborder="0" marginwidth="0" marginheight="0" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen></iframe>
      </div>
    </ModalBody>
   
  </Modal>
  
  
       
         
  
       
  <Stack className='UpperMedicRow' direction="horizontal" gap={3}>
    
    <Stack  className="ColLeft" gap={2}>
          
  
            
              <Card className='CardUser rounded-4'>
                     <h4 className='text-center '>Juan Pérez</h4>
                   <Stack direction="horizontal" className="StackUser">
                
                      <div ><Stack className="mb-5 StackStack" gap={3}>
                   <Card.Img
                    src={sobre}
                    alt="avatar"
                    className="rounded-4 avatar"
                    fluid/>
         
                 
                 
                    </Stack> </div> 
                    <Stack className='StackInfo' gap={0}>
  
                      <Card.Body className='StackInfo'>
                          <Stack  gap={2}>
                
                   <div className='InfoBorder'>   <h4>Information</h4> </div>
              
  
                       <Stack direction="horizontal" gap={2}>
                          <h6 className='InfoSubTitles'>Rango</h6>
                          <h6>Teniente</h6>
                           </Stack>     <Stack direction="horizontal" gap={2}>
                          <h6 className='InfoSubTitles'>Especialidad</h6>
                          <h6>Submarino</h6>
                        </Stack>
                     
        
                          <h5>Antecedentes Personales</h5>
                      <Stack direction="horizontal" gap={2}>
                          <h6 className='Antecedentes'>Sobrepeso</h6>
                          <h6 className='Antecedentes'>Tabaquismo</h6>
                          </Stack>
                       
                       
                      <h5>Antecedentes Familiares</h5>
                      <Stack direction="horizontal" gap={2}>
                          <h6 className='Antecedentes'>Diabetes</h6>
                          </Stack>
                            
                       
                           </Stack>
                    </Card.Body>
                    
                  
                      </Stack>
               </Stack>
              </Card>
              
        
  
  
  
                </Stack>
          
                
          <Stack className="ColRight" gap={3}>
  
         
         <div className='ms-auto' > 
                  <Button onClick={() => setFormsShow(true)}  size="lg" className='rounded-4 buttonMedic'>Agregar Interconsulta</Button> 
             
              </div>
      
  
              <Row>
                <Col>
                <Stack gap={3}>
                  <Card className="CardMedicine rounded-4">
                  <Stack direction='horizontal' gap={0}>   
                 
                  <Card.Body>  <h4 className='nombretabla'> Síndrome Metabólico</h4>
                   <Stack direction='horizontal'>  <Card.Img
                    src={cruz}
                    alt="avatar"
                    className="rounded-circle imgMedicine bg-white"
                    fluid/>
                         
                          <h4 className='colorestabla'> C/ SINDROME </h4> </Stack>
                          <h5 className='text-center'> 
9 meses dúltima atención </h5>
                     
                  </Card.Body>
                  </Stack>
                  </Card>
                  <Card className="CardMedicine rounded-4">
                  <Stack direction='horizontal' gap={0}>   
                 
                  <Card.Body>  <h4 className='nombretabla'> Riesgo Cardiovascular </h4>
                   <Stack direction='horizontal'>  <Card.Img
                    src={cora}
                    alt="avatar"
                    className="rounded-circle imgMedicine bg-white"
                    fluid/>
                         
                          <h4 className='colorestabla'> 23.5% ALTO </h4> </Stack>
                          <h5 className='text-center'> 9 meses dúltima atención </h5>
                     
                  </Card.Body>
                  </Stack>
                  </Card>
                 
              </Stack>
                </Col>
                <Col>
                <Card className="GraphMedicine rounded-4">
                  <Card.Body>
                  <h4 className='RhythmCardiac'> Peso por Control </h4>
                    <div className='ProfileGraph1'>
                    <CardiacGraph />
                    </div>
                  </Card.Body>
                  </Card>
</Col>
                  </Row>
                  </Stack></Stack>

                  <Row lg={2} className='AdminRow1 g-2'>
        
        <Col lg={5}>
          <div className='cardUserProf3 rounded-4 border'>
            <div className='LastEvalBox rounded-3 border border-info'>
              <Stack direction="horizontal" className='p-2'>
                <img src={check} className='CheckIconStack'/>
                <Stack className='UltEvStack'>
                  <span className='UltEv1'>Ultima evaluación</span>
                  <span className='UltEv2'>NO APTO</span>
                  <span className='UltEv1'><span className='bluey'>9 meses</span> desde la última atención</span>
                </Stack>
              </Stack>
            </div>
            <Stack direction="horizontal" className='IMCStack p-2 rounded'>
              <span className=''>IMC:</span>
              <span className='ms-auto'>OBESIDAD II</span>
            </Stack>
            <div>
              <Stack direction="horizontal" className='DateControl'>
                <span className='DateItself ms-auto'>Control Anterior:<br/>04/05/2023</span>
                <span className='DateItself'>Último Control:<br/>04/07/2023</span>
              </Stack>
              <Stack direction="horizontal" className='PhysDataStack p-2 rounded'>
                <span className=''>Peso:</span>
                <div className='Greenery ms-auto rounded-3'>
                  <span>127 kg</span>
                </div>
                <div className='Greenery rounded-3'>
                  <span>120 kg</span>
                </div>
              </Stack>
              <Stack direction="horizontal" className='PhysDataStack p-2 rounded'>
                <span className=''>Talla:</span>
                <div className='Greenery ms-auto rounded-3'>
                  <span>183</span>
                </div>
                <div className='Greenery rounded-3'>
                  <span>183</span>
                </div>
              </Stack>
              <Stack direction="horizontal" className='PhysDataStack p-2 rounded'>
                <span className=''>% Grasa:</span>
                <div className='Greenery ms-auto rounded-3'>
                  <span>33.5</span>
                </div>
                <div className='Greenery rounded-3'>
                  <span>30.8</span>
                </div>
              </Stack>
              <Stack direction="horizontal" className='PhysDataStack p-2 rounded'>
                <span className=''>Cintura:</span>
                <div className='Greenery ms-auto rounded-3'>
                  <span>125</span>
                </div>
                <div className='Greenery rounded-3'>
                  <span>120</span>
                </div>
              </Stack>
              <Stack direction="horizontal" className='PhysDataStack p-2 rounded'>
                <span className=''>Presión Arterial</span>
                <div className='Greenery ms-auto rounded-3'>
                  <span>165/98</span>
                </div>
                <div className='Greenery rounded-3'>
                  <span>155/98</span>
                </div>
              </Stack>
            </div>
          </div>
        </Col>
        <Col lg={7}>
          <div className='cardUserProf4 rounded-4 border p-4'>
            <div className='RiskIndicators'>
              <span>Indicadores de Riesgo</span>
            </div>
            <Stack direction="horizontal" className='HeaderTags p-2'>
              <span className='RiskTag1 ms-auto'>Último Control</span>
              <span className='RiskTag2'>Control Actual</span>
              <span className='RiskTag3'>Rango Óptimo</span>
            </Stack>
            <div className='RiskIndicatorsBox rounded-4 p-3'>
              <Stack direction="horizontal" className='RiskTable p-2'>
                <Stack className='RiskTableHead'>
                  <span className='RiskSpan1'>Índice Aterogénico Plasmático</span>
                  <span className='RiskSpan2'>Hace 3 semanas</span>
                </Stack>
                <span className='RiskSpan3 ms-auto'>180</span>
                <span className='RiskSpan4'>180</span>
                <span className='RiskSpan5'>180</span>
              </Stack>
              <div className='horizontalDivider'></div>
              <Stack direction="horizontal" className='RiskTable p-2'>
                <Stack className='RiskTableHead'>
                  <span className='RiskSpan1'>Colesterol Total Venoso</span>
                  <span className='RiskSpan2'>Hace 1 año</span>
                </Stack>
                <span className='RiskSpan3 ms-auto'>95</span>
                <span className='RiskSpan4'>95</span>
                <span className='RiskSpan5'>95</span>
              </Stack>
              <div className='horizontalDivider'></div>
              <Stack direction="horizontal" className='RiskTable p-2'>
                <Stack className='RiskTableHead'>
                  <span className='RiskSpan1'>Glicemia</span>
                  <span className='RiskSpan2'>Hace 1 año</span>
                </Stack>
                <span className='RiskSpan3 ms-auto'>30</span>
                <span className='RiskSpan4'>30</span>
                <span className='RiskSpan5'>30</span>
              </Stack>
              <div className='horizontalDivider'></div>
              <Stack direction="horizontal" className='RiskTable p-2'>
                <Stack className='RiskTableHead'>
                  <span className='RiskSpan1'>Colesterol HDL</span>
                  <span className='RiskSpan2'>Hace 1 año</span>
                </Stack>
                <span className='RiskSpan3 ms-auto'>70</span>
                <span className='RiskSpan4'>70</span>
                <span className='RiskSpan5'>70</span>
              </Stack>
            </div>
          </div>
        </Col>
       </Row>
       <Button variant="primary" className='OpenForms rounded-circle' onClick={() => setiframeShow(true)}>?</Button>   
         
  </div>
  );
};

export default PatientProfile;