/* Copiado de la carpeta de ymc pa tener donde 
import "@fontsource/montserrat";
*/
import React from "react";
import ReactDOM from "react-dom/client";
import CardsMid from "./Components/cardsmidman";
import LoginPage from "./LoginPage";
import UserPage from "./UserPage";
import UserProfilePage from "./UserProfilePage";
import MedicPage from "./MedicViewPage";
import MedicalContextProvider from "./Components/Context/MedicalContext";
import FinalExamsContextProvider from "./Components/Context/FinalExamsContext";
import AdminPage from "./AdminViewPage";
import FormsPage from "./FormsPage";
import ReasonsPage from "./ReasonsPage";
import PersonalMedicalHistoryPage from "./PersonalMedicalHistoryPage";
import ThankYouPage from "./ThankYouPage";

import reportWebVitals from "./reportWebVitals";
import "./index.css";
/*
import PdfViewerPage from "./PdfViewerPage";*/

import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
//import ReactGA from "react-ga";
//const TRACKING_ID = "G-5TSZG25VY0"; // OUR_TRACKING_ID
//ReactGA.initialize(TRACKING_ID);

//importar arriba las páginas, y poner aquí abajo, editar los nombres de path
const Router = createBrowserRouter([
  {
    /* Poner para que boton lleve a home  <Link to="/">Home</Link> */
    path: "User",
    element: (
      <React.StrictMode>
       <UserPage /> 
      </React.StrictMode>
    ),
  },
  {
    /* Poner para que boton lleve a home  <Link to="/">Home</Link> */
    path: "Profile",
    element: (
      <React.StrictMode>
       <UserProfilePage /> 
      </React.StrictMode>
    ),
  },
  {
    /* Poner para que boton lleve a home  <Link to="/">Home</Link> */
    path: "/loading",
    element: (
      <React.StrictMode>
      <FinalExamsContextProvider>
   <MedicalContextProvider>
   <CardsMid /> 
 </MedicalContextProvider>
  </FinalExamsContextProvider>
      </React.StrictMode>
     
    
    ),
  },
  {
    /* Poner para que boton lleve a home  <Link to="/">Home</Link> */
    path: "/",
    element: (
      <React.StrictMode>
       <LoginPage /> 
      </React.StrictMode>
    ),
  },
  {
    /* Poner para que boton lleve a home  <Link to="/">Home</Link> */
    path: "Medico",
    element: (
      <React.StrictMode>
       <MedicPage /> 
      </React.StrictMode>
    ),
  },
  {
    /* Poner para que boton lleve a home  <Link to="/">Home</Link> */
    path: "Admin",
    element: (
      <React.StrictMode>
       <AdminPage /> 
      </React.StrictMode>
    ),
  },
 
  {
    /* Poner para que boton lleve a pdf viewer  <Link to="/ViewOrder">Placeholder</Link> */
    path: "VerOrden",
    element: (
      <React.StrictMode>
        <ThankYouPage />
      </React.StrictMode>
    ),
  },
  {
    /* Poner para que boton lleve a pdf   <Link to="/PDF">Placeholder</Link> */
    path: "Seleccion",
    element: (
      <React.StrictMode>
        <ReasonsPage />
      </React.StrictMode>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={Router} />);

/*
const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

reportWebVitals(SendAnalytics);*/
