import { LoginContext } from "./Context/LoginContext";
import {useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthUser = () => {
    const NavigateAuth = useNavigate();
    const { validador,setvalidador } = useContext(LoginContext);
    const CheckAuth = () =>{
        if (validador === 1) {
        
        }

        else {
        NavigateAuth("/");
        }
    }
    useEffect(() => {
        CheckAuth();
      }, []);
};

export default AuthUser;