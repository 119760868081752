import React from "react";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import doc from "./Photos/YMC_Logo_W.png";
import Mediguard from "./Photos/MEDIGUARD.png";
import firm from "./Photos/firma.png";
import MyCustomFont from "./Fonts/Montserrat-Medium.ttf";
import { useContext } from "react";
import { MedicalContext } from "./Context/MedicalContext";
Font.register({
  family: "Montserrat",
  src: MyCustomFont,
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 85,
    paddingBottom: 65,
    paddingHorizontal: 35,
    marginTop: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  Fecha: {
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "left",
  },
  Diag: {
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "center",
    marginTop: -28,
  },
  Edad: {
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  Nombre: {
    width: "250px",
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "left",
    marginTop: -28,
  },
  Rut: {
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "right",
    marginTop: -28,
  },
  image: {
    width: "15%",
    position: "absolute",
    top: 22,
    left: 15,
  },
  Fondo: {
    width: "100%",
    opacity: 0.2,
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    fontFamily: "Montserrat",
    textAlign: "center",
    color: "#0D5FB7",
  },
  id: {
    fontSize: 12,
    marginBottom: 20,
    position: "absolute",
    top: 22,
    right: 15,
    color: "#0D5FB7",
  },
  Reali: {
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    marginTop: 35,
  },
  examen: {
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    marginTop: 15,
  },
  fir: {
    width: "40%",
    alignSelf: "center",
  },
  Dra: {
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
});

const PDFFile = ({ Name }) => {
  const edad = 36,
    id = 323133,
    Rut = "11.111.111-1",
    Nombre = "aaa",
    examenes = "Hemoglobina, test inmunológico cuantitativo";
  return (
    <Document>
      <Page size="A4" style={styles.body} debug={false}>
        <Image style={styles.image} src={doc} wrap={false}/>
        <Text style={styles.id} fixed>
          N° {id}
        </Text>
        <Text style={styles.header} fixed>
          ORDEN DE EXAMEN
        </Text>
        <Text style={styles.Edad} fixed debug={false}>
          Edad: {edad}
        </Text>
        <Text style={styles.Rut} fixed debug={false}>
          Rut: {Rut}
        </Text>
        <Text style={styles.Nombre} fixed debug={false}>
          Nombre:{Name}
        </Text>
        <Text style={styles.Fecha} debug={false}>
          Fecha: {new Date().getDate()}/{new Date().getMonth()}/
          {new Date().getFullYear()}
        </Text>
        <Text style={styles.Diag} debug={false}>
          Diagnostico: Control Médico
        </Text>
        <Text style={styles.Reali}>Realizar:</Text>
        <Text style={styles.examen}>aaa</Text>
        <Image style={styles.Fondo} src={Mediguard} />
        <Image style={styles.fir} src={firm} debug={false} wrap={false}/>
        <Text style={styles.Dra} wrap={false}> Jorge Eduardo Caro Diaz</Text>
        <Text style={styles.Dra} wrap={false}>Rut: 15.946.308-7</Text>
      </Page>
    </Document>
  );
};

export default PDFFile;
