import React, { useState, useContext, useEffect } from "react";
import {
  PDFDownloadLink,
  StyleSheet,
  View,
  Page,
  Text,
  Image,
  Document,
  Font,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import "./Styles/dolor.css";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { MedicalContext } from "./Context/MedicalContext";
import { FormsContext } from "./Context/formsContext";
import { LoginContext } from "./Context/LoginContext";
import { isMobile } from "react-device-detect";
import doc from "./Photos/logo_hnt.png";
import Medigaurd from "./Photos/MEDIGUARD.png";
import firm from "./Photos/firma.png";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FinalExamsContext } from "./Context/FinalExamsContext";
import Sticky from 'react-stickynode';

import ConfettiExplosion from 'react-confetti-explosion';
import { Stack } from "react-bootstrap";

const styles = StyleSheet.create({
  caja: {
    display: "flex",
    justifyContent: "center",
  },
  iframe: {
    height: "1082px",
    width: "840px",
  },
  body: {
    paddingTop: 85,
    paddingBottom: 300,
    paddingHorizontal: 35,
    marginTop: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  Fecha: {
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "left",
  },
  Diag: {
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "center",
    marginTop: -28,
  },
  Edad: {
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  Nombre: {
    width: "250px",
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "left",
    marginTop: -28,
  },
  Rut: {
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "right",
    marginTop: -28,
  },
  image: {
    width: "30%",
    position: "absolute",
    top: 22,
    left: 15,
  },
  Fondo: {
    width: "100%",
    opacity: 0.2,
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  id: {
    fontSize: 12,
    marginBottom: 20,
    position: "absolute",
    top: 22,
    right: 15,
    color: "#0D5FB7",
  },
  Reali: {
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    marginTop: 35,
  },
  examen: {
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    marginTop: 15,
  },
  fir: {
    width: "40%",
    alignSelf: "center",
  },
  Dra: {
    margin: 12,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  list: {
    display: "flex",
    flexDirection: "column",
  },
  fixing:{
    position: 'absolute',
    bottom: 75,
    left: 0,
    right: 0
  },
});

const App = () => {
 

  const { Name, secondName, DateBirth, Rutificador, email } =
    useContext(FormsContext);

  const { OutputListOfExams ,ListOfPacks,age
  } = useContext(MedicalContext);

  const { User
  } = useContext(LoginContext);
  const [DateMedical, setDateMedical] = useState(new Date());
  const month = DateMedical.getMonth() + 1;


  const math = new Date().getFullYear() - DateBirth;
  const Mydoc = () => (
    <Document >
      <Page size="A4" style={styles.body} debug={false} >
        <Image style={styles.image} src={doc} />

        <Text style={styles.header} fixed>
          ORDEN DE EXAMEN
        </Text>
        <Text style={styles.Edad} fixed debug={false}>
          Edad: 38
        </Text>
        <Text style={styles.Rut} fixed debug={false}>
          Rut: {User}
        </Text>
        <Text style={styles.Nombre} fixed debug={false}>
          Nombre: Juan Pérez
        </Text>
        <Text style={styles.Fecha} fixed debug={false}>
          Fecha: {DateMedical.getDate()}/{month}/{DateMedical.getFullYear()}
        </Text>
        <Text style={styles.Diag}fixed debug={false}>
          Diagnostico: Control Médico
        </Text>
        
        <Text style={styles.Reali} fixed>Realizar</Text>
        <View height={10}>
        <Text style={styles.examen}>ELECTROCARDIOGRAMA EN REPOSO</Text>
        <Text style={styles.examen}>RX. TÓRAX</Text>
        </View>
        <View height={72} style={styles.fixing} fixed>
        <Image style={styles.fir} fixed src={firm} debug={false} />
        <Text style={styles.Dra}fixed> Jorge Eduardo Caro Diaz</Text>
        <Text style={styles.Dra}fixed>Rut: 15.946.308-7</Text>
        </View>

      </Page>
    </Document>
  );


 
  const [ViewOrder, setViewOrder] = useState(false);

  const LabButton = () => {
      return (
        <Col>
        <View style={styles.caja} className="PDF_View">
            <div className="PrintOrderDiv">
              <PDFDownloadLink
                document={<Mydoc />}
                fileName="Orden medica">
                {({ loading }) =>
                  loading ? (
                    <Button
                      className="PrintOrderButton rounded-pill"
                      size="ld"
                      variant="primary"
                      textAlign="left">
                      Cargando Documento...
                    </Button>
                  ) : (
                    <Button
                      className="PrintOrderButton rounded-pill"
                      size="ld"
                      variant="primary"
                      textAlign="left">
                      Descargar Orden
                    </Button>
                  )
                }
              </PDFDownloadLink>
            </div>
        </View>
        </Col>
      );
  };
  return (
    <>
    <Sticky enabled={true} bottomBoundary={1200}>

    </Sticky>
    <div className="App BackGround">
  <ConfettiExplosion className="Confetti" />      
      <h1 className="ThankYouMessage">Tu orden de exámenes ha sido creada con éxito</h1>
      <h3 className="ThankYouMessageSub">Puedes descargar las órdenes con los botones a continuación</h3>
      <Row className="stackButtonPDFs" lg={3} sm={2} xs={1}>
      <LabButton />
      </Row>
      <Row className="NextBeforeButtons">
                
                </Row>
    </div>
    </>
  );
};

export default App;

/*  <Image style={styles.Fondo} fixed src={Medigaurd} />*/
