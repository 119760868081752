import "./Styles/UserData.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PDFFile from "./PDFFile";
import Stack from "react-bootstrap/Stack";
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Computer from "./Photos/CompUter.png";
import React from 'react'
import ReactDOM from 'react-dom'
import AnyChart from 'anychart-react'
import anychart from 'anychart'
import { useEffect ,useContext,useState} from 'react';
import CardiacGraph from './CardiacGraph'
import { MedicalContext } from "./Context/MedicalContext";
import { FormsContext } from "./Context/formsContext";
import { LoginContext } from "./Context/LoginContext";
import {
    PDFDownloadLink,
    StyleSheet,
    View,
    Page,
    Text,
    Image,
    Document,
    Font,
  } from "@react-pdf/renderer";

  import firm from "./Photos/firma.png";
import doc from "./Photos/logo_hnt.png";
function graphHeart(){
    // create data set on our data
    var dataSet = anychart.data.set(getData());

    // map data for the first series, take x from the zero column and value from the first column of data set
    var firstSeriesData = dataSet.mapAs({ x: 0, value: 1 });

    // map data for the second series, take x from the zero column and value from the second column of data set
    var secondSeriesData = dataSet.mapAs({ x: 0, value: 2 });

    // create line chart
    var chart = anychart.line();

    // turn on chart animation
    chart.animation(true);

    // set chart padding
    chart.padding([10, 20, 5, 20]);

    // turn on the crosshair
    chart.crosshair().enabled(true).yLabel(false).yStroke(null);

    // set tooltip mode to point
    chart.tooltip().positionMode('point');

    // set chart title text settings
    chart.title(
      ''
    );

    // set yAxis title
    chart.yAxis().title('Factor de Riesgo');
    chart.xAxis().labels().padding(5);

    // create first series with mapped data
    var firstSeries = chart.line(firstSeriesData);
    firstSeries.name('Proyección actual');
    firstSeries.hovered().markers().enabled(true).type('circle').size(4);
    firstSeries
      .tooltip()
      .position('right')
      .anchor('left-center')
      .offsetX(5)
      .offsetY(5);

    // create second series with mapped data
    var secondSeries = chart.line(secondSeriesData);
    secondSeries.name('Regresión lineal');
    secondSeries.hovered().markers().enabled(true).type('circle').size(4);
    secondSeries
      .tooltip()
      .position('right')
      .anchor('left-center')
      .offsetX(5)
      .offsetY(5);

    // turn the legend on
    chart.legend().enabled(true).fontSize(13).padding([0, 0, 10, 0]);

    // set container id for the chart
    chart.container('container');
    // initiate chart drawing
    chart.draw();
    return chart
  }

  function getData() {
    return [
      ['1986', 3.6, 2.3, 2.8, 11.5],
      ['1987', 7.1, 4.0, 4.1, 14.1],
      ['1988', 8.5, 6.2, 5.1, 17.5],
      ['1989', 9.2, 11.8, 6.5, 18.9],
      ['1990', 10.1, 13.0, 12.5, 20.8],
      ['1991', 11.6, 13.9, 18.0, 22.9],
      ['1992', 16.4, 18.0, 21.0, 25.2],
      ['1993', 18.0, 23.3, 20.3, 27.0],
      ['1994', 13.2, 24.7, 19.2, 26.5],
      ['1995', 12.0, 18.0, 14.4, 25.3],
      ['1996', 3.2, 15.1, 9.2, 23.4],
      ['1997', 4.1, 11.3, 5.9, 19.5],
      ['1998', 6.3, 14.2, 5.2, 17.8],
      ['1999', 9.4, 13.7, 4.7, 16.2],
      ['2000', 11.5, 9.9, 4.2, 15.4],
      ['2001', 13.5, 12.1, 1.2, 14.0],
      ['2002', 14.8, 13.5, 5.4, 12.5],
      ['2003', 16.6, 15.1, 6.3, 10.8],
      ['2004', 18.1, 17.9, 8.9, 8.9],
      ['2005', 17.0, 18.9, 10.1, 8.0],
      ['2006', 16.6, 20.3, 11.5, 6.2],
      ['2007', 14.1, 20.7, 12.2, 5.1],
      ['2008', 15.7, 21.6, 10, 3.7],
      ['2009', 12.0, 22.5, 8.9, 1.5]
    ];
  } 

function UserData() {


    const styles = StyleSheet.create({
        caja: {
          display: "flex",
          justifyContent: "center",
        },
        iframe: {
          height: "1082px",
          width: "840px",
        },
        body: {
          paddingTop: 85,
          paddingBottom: 300,
          paddingHorizontal: 35,
          marginTop: 20,
        },
        title: {
          fontSize: 24,
          textAlign: "center",
        },
        Fecha: {
          margin: 12,
          fontSize: 12,
          fontFamily: "Montserrat",
          textAlign: "left",
        },
        Diag: {
          margin: 12,
          fontSize: 12,
          fontFamily: "Montserrat",
          textAlign: "center",
          marginTop: -28,
        },
        Edad: {
          margin: 12,
          fontSize: 12,
          fontFamily: "Montserrat",
          textAlign: "center",
        },
        Nombre: {
          width: "250px",
          margin: 12,
          fontSize: 12,
          fontFamily: "Montserrat",
          textAlign: "left",
          marginTop: -28,
        },
        Rut: {
          margin: 12,
          fontSize: 12,
          fontFamily: "Montserrat",
          textAlign: "right",
          marginTop: -28,
        },
        image: {
          width: "30%",
          position: "absolute",
          top: 22,
          left: 15,
        },
        Fondo: {
          width: "100%",
          opacity: 0.2,
          display: "flex",
          justifyContent: "center",
          alignSelf: "center",
        },
        header: {
          fontSize: 12,
          marginBottom: 20,
          fontFamily: "Montserrat",
          textAlign: "center",
        },
        id: {
          fontSize: 12,
          marginBottom: 20,
          position: "absolute",
          top: 22,
          right: 15,
          color: "#0D5FB7",
        },
        Reali: {
          margin: 12,
          fontSize: 12,
          fontFamily: "Montserrat",
          marginTop: 35,
        },
        examen: {
          margin: 12,
          fontSize: 12,
          fontFamily: "Montserrat",
          marginTop: 15,
        },
        fir: {
          width: "40%",
          alignSelf: "center",
        },
        Dra: {
          margin: 12,
          fontSize: 12,
          fontFamily: "Montserrat",
          textAlign: "center",
        },
        list: {
          display: "flex",
          flexDirection: "column",
        },
        fixing:{
          position: 'absolute',
          bottom: 75,
          left: 0,
          right: 0
        },
      });
      
       
      
        const { Name, secondName, DateBirth, Rutificador, email } =
          useContext(FormsContext);
      
        const { OutputListOfExams ,ListOfPacks,age
        } = useContext(MedicalContext);
      
        const { User
        } = useContext(LoginContext);
        const [DateMedical, setDateMedical] = useState(new Date());
        const month = DateMedical.getMonth() + 1;
      
      
        const math = new Date().getFullYear() - DateBirth;
        const Mydoc = () => (
          <Document >
            <Page size="A4" style={styles.body} debug={false} >
              <Image style={styles.image} src={doc} />
      
              <Text style={styles.header} fixed>
                ORDEN DE EXAMEN
              </Text>
              <Text style={styles.Edad} fixed debug={false}>
                Edad: 38
              </Text>
              <Text style={styles.Rut} fixed debug={false}>
                Rut: {User}
              </Text>
              <Text style={styles.Nombre} fixed debug={false}>
                Nombre: Juan Pérez
              </Text>
              <Text style={styles.Fecha} fixed debug={false}>
                Fecha: {DateMedical.getDate()}/{month}/{DateMedical.getFullYear()}
              </Text>
              <Text style={styles.Diag}fixed debug={false}>
                Diagnostico: Control Médico
              </Text>
              
              <Text style={styles.Reali} fixed>Realizar</Text>
              <View height={10}>
              <Text style={styles.examen}>ELECTROCARDIOGRAMA EN REPOSO</Text>
              <Text style={styles.examen}>RX. TÓRAX</Text>
              </View>
              <View height={72} style={styles.fixing} fixed>
              <Image style={styles.fir} fixed src={firm} debug={false} />
              <Text style={styles.Dra}fixed> Jorge Eduardo Caro Diaz</Text>
              <Text style={styles.Dra}fixed>Rut: 15.946.308-7</Text>
              </View>
      
            </Page>
          </Document>
        );

    const { t } = useTranslation();
    return (
        <div className='UserBody'>
            <div>
                <h3 className="UserWelcome">LE DAMOS LA BIENVENIDA A LA PLATAFORMA DE MEDICINA PREVENTIVA</h3>
            </div>

            <div className="ProfileStack">
                <Stack direction="horizontal" className="ProfileInfo rounded-4" gap={3}>
                    <div className="stack1Item1">
                        <div className="item1span1">
                            <span>PERFIL DE USUARIO</span>
                        </div>
                        <div className="item1span2">
                            <span>Visualiza tu perfil e información de usuario, junto a detalles de salud personales.</span>
                        </div>
                    </div>
                    <div className="stack1Item2">
                    <Link to="/Profile">
                        <Button variant="primary" className="CompleteInfo rounded-pill border-2 border-primary">
                            Ver Perfil
                        </Button>
                        </Link>
                    </div>
                </Stack>
            </div>


            <div className="FirstStack">
                <Stack direction="horizontal" className="EnterInfo rounded-4" gap={3}>
                    <div className="stack1Item1">
                        <div className="item1span1">
                            <span>GENERE UNA ORDEN MÉDICA</span>
                        </div>
                        <div className="item1span2">
                            <span>Obtén tu orden médica basada en tus datos registrados.</span>
                        </div>
                    </div>
                    <div className="stack1Item2">
                    <PDFDownloadLink
                document={<Mydoc />}
                fileName="Orden medica">
                                            <Button variant="primary" className="CompleteInfo rounded-pill border-2 border-primary">
                            Completar Información
                        </Button>
                        </PDFDownloadLink>
                    </div>
                </Stack>
            </div>
            {/*
            <div className="DerivateStack rounded-4 border border-primary">
                <Stack direction="horizontal" gap={5}>
                    <div className="stack2Item1 p-2">
                        <div className="stack2item1span1">
                            <span>
                                Has sido derivado a consulta de medicina preventiva
                            </span>
                        </div>
                    </div>
                    <div className="stack2Item2 p-2 ms-auto">
                        <div className="stack2item2span1">
                            <span>Miercoles 05 de Agosto 2023</span>
                        </div>
                        <div className="stack2item2span2">
                            <span>Ubicación del Hospital o Laboratorio</span>
                        </div>
                    </div>
                </Stack>
                <Stack direction="horizontal" className="RequireStack rounded-4" gap={3}>
                    <div className="stack1Item1">
                        <div className="item1span1">
                            <span>Debes presentarte con los siguientes exámenes y/o interconsultas</span>
                        </div>
                        <div className="item1span2">
                            <span>Basado en tecnología predictiva hemos generado este set de exámenes para hacer más expedita tu atención en medicina preventiva. Debes presentarte a tu consulta con ellos.</span>
                        </div>
                    </div>
                    <div className="stack1Item2">
                        <Button variant="primary" className="SeeMedicalOrder rounded-pill border-2 border-primary">
                            Ver Orden Médica
                        </Button>
                    </div>
                </Stack>
            </div>
            
            <div className="DataGrid">
                <Row>
                    <Col className="HeartAge rounded-4">
          
                        <Stack direction="horizontal" gap={3}>
                            <div className="stack3Item2">
                            <img src={Computer} className="Puter" alt="puter" />
                            </div>
                            <div className="stack3Item1">
                                <div className="stack3item1span1">
                                    <span>La edad de tu corazón</span>
                                </div>
                                <div className="stack3item1span2">
                                    <span>Tienes el corazón de una persona de 45 años</span>
                                </div>
                                <div className="stack3item1span3">
                                    <span>Tu corazón debido a tus antecedentes y hábitos presenta una edad percibida mayor a la recomendada</span>
                                </div>
                                
                            </div>
                        </Stack>
                        <div className="HeartAgeSpan rounded-4">
                            <span variant="primary" className="HeartAgeSpanInner">
                                46
                            </span>
                        </div>
                    </Col>
                    <Col>
                        <Stack className="ChartStack rounded-4" gap={3}>
                            <div className="stack4_t1">
                            <span className="stack4span1">Proyección de riesgo cardiaco</span>
                            </div>
                            <div className="stack4_t2">
                            <span className="stack4span2">El envejecimiento prematuro de tu corazón generará un alto riesgo cardiaco para los próximos 10 años</span>
                            </div>
                            <div className="graph1">
                                <div id="container">
                                    {useEffect(() => {
                                    const chart = graphHeart();

                                    // Return a cleanup function to remove the chart when the component unmounts
                                    return () => chart.dispose();
                                    }, [])}
                                </div>
                            </div>
                        </Stack>
                    </Col>
                </Row>
                                </div> */}
        </div>
    );
}

export default UserData;